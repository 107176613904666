import { Component, OnInit } from '@angular/core';
import { Router } from '@angular/router';
import {
  getAutoRedirectMode,
  getAutoRedirectPath,
  setAutoRedirectPath,
} from '../common/autoRedirect.util';

@Component({
  selector: 'app-cancel-redirect-button',
  templateUrl: './cancel-auto-redirect-button.component.html',
  styleUrls: ['./cancel-auto-redirect-button.component.scss'],
})
export class CancelAutoRedirectButtonComponent implements OnInit {
  constructor(public route: Router) {}

  clicked = 0;
  timeout;

  ngOnInit(): void {
    const path = getAutoRedirectPath();
    const isAutoRedirectMode = getAutoRedirectMode();
    if (isAutoRedirectMode && path) this.route.navigate([path]);
  }

  handleClick(e) {
    if (this.timeout) {
      clearTimeout(this.timeout);
    }

    this.clicked++;

    if (this.clicked === 3) {
      setAutoRedirectPath('');
      this.route.navigate(['/main-page']);
      this.clicked = 0;
      return;
    }

    this.timeout = setTimeout(() => {
      this.clicked = 0;
      this.timeout = null;
    }, 5000);
  }

  showCancelRedirectButton() {
    return !['/', '', '/main-page', '/login', '/logout'].includes(
      this.route.url
    );
  }
}
