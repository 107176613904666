<div id="printer-page-data" class="montain-bg-wrapper">
  <div class="printer-left-body" [ngStyle]="{ background: printerQueue.backgroundColor }">
    <div class="printer-left-body-content">
      <img (click)="refresh()" src="{{ printerQueue?.logo }}" alt="logo" />
      <h1 [ngStyle]="{ color: printerQueue.labelColor }">
        {{ 'qrBased_savePaper' | translate : branchService.details.locale }}
      </h1>
      <h2 [ngStyle]="{ color: printerQueue.labelColor }">
        {{ 'qrBased_useMobileCamera' | translate : branchService.details.locale }}
      </h2>
      <qr-code
        [value]="[mobileWebAppUrl, printerQueue.qrCodeText].join('')"
        [size]="printerQueue.qrSize"
        [level]="'H'"
        [foreground]="printerQueue.qrCodeColor"
        class="qr-image"
      >
      </qr-code>
      <div class="printer-qr-instructions" [ngStyle]="{ color: printerQueue.labelColor }">
        <label [ngStyle]="{ color: printerQueue.labelColor }">{{
          'qrBased_instructions' | translate : branchService.details.locale
        }}</label>
        <hr [ngStyle]="{ 'border-color': printerQueue.labelColor }" />
        <div class="printer-qr-instructions-step" [ngStyle]="{ color: printerQueue.labelColor }">
          <span [ngStyle]="{ color: printerQueue.labelColor }">1.</span>
          <div [ngStyle]="{ color: printerQueue.labelColor }">
            <p class="printer-qr-instructions-step-text">
              <svg
                class="printer-qr-instructions-icon"
                xmlns="http://www.w3.org/2000/svg"
                xmlns:xlink="http://www.w3.org/1999/xlink"
                version="1.1"
                id="Capa_1"
                x="0px"
                y="0px"
                viewBox="0 0 512 512"
                xml:space="preserve"
                width="512"
                height="512"
              >
                <g [attr.fill]="printerQueue.labelColor">
                  <path
                    d="M185.255,512c-76.201-0.439-139.233-155.991-139.233-235.21c0-129.404,97.075-157.734,134.487-157.734   c16.86,0,34.863,6.621,50.742,12.48c11.104,4.087,22.588,8.306,28.975,8.306c3.823,0,12.832-3.589,20.786-6.738   c16.963-6.753,38.071-15.146,62.651-15.146c0.044,0,0.103,0,0.146,0c18.354,0,74.004,4.028,107.461,54.272l7.837,11.777   l-11.279,8.511c-16.113,12.158-45.513,34.336-45.513,78.267c0,52.031,33.296,72.041,49.292,81.665   c7.061,4.248,14.37,8.628,14.37,18.208c0,6.255-49.922,140.566-122.417,140.566c-17.739,0-30.278-5.332-41.338-10.034   c-11.191-4.761-20.845-8.862-36.797-8.862c-8.086,0-18.311,3.823-29.136,7.881C221.496,505.73,204.752,512,185.753,512H185.255z"
                  />
                  <path d="M351.343,0c1.888,68.076-46.797,115.304-95.425,112.342C247.905,58.015,304.54,0,351.343,0z" />
                </g>
              </svg>
              /
              <svg
                class="printer-qr-instructions-icon"
                version="1.1"
                id="Capa_1"
                xmlns="http://www.w3.org/2000/svg"
                xmlns:xlink="http://www.w3.org/1999/xlink"
                x="0px"
                y="0px"
                viewBox="0 0 512.007 512.007"
                style="enable-background: new 0 0 512.007 512.007"
                xml:space="preserve"
              >
                <g>
                  <path
                    [attr.fill]="printerQueue.labelColor"
                    d="M64.004,192.007c-17.664,0-32,14.336-32,32v128c0,17.664,14.336,32,32,32s32-14.336,32-32v-128
                  C96.004,206.343,81.668,192.007,64.004,192.007z"
                  />
                  <path
                    [attr.fill]="printerQueue.labelColor"
                    d="M448.004,192.007c-17.664,0-32,14.336-32,32v128c0,17.664,14.336,32,32,32s32-14.336,32-32v-128
                  C480.004,206.343,465.668,192.007,448.004,192.007z"
                  />
                  <path
                    [attr.fill]="printerQueue.labelColor"
                    d="M128.004,385.863c0,17.664,14.336,32,32,32v62.144c0,17.664,14.336,32,32,32s32-14.336,32-32
                  v-62.144h64v62.144c0,17.664,14.336,32,32,32s32-14.336,32-32v-62.144c17.664,0,32-14.336,32-32v-192h-256V385.863z"
                  />
                  <path
                    [attr.fill]="printerQueue.labelColor"
                    d="M335.876,60.711l28.48-34.528c5.632-6.816,4.672-16.896-2.144-22.528
                  c-6.848-5.6-16.896-4.672-22.528,2.144l-31.136,37.728c-16.064-7.264-33.76-11.52-52.544-11.52c-19.04,0-36.96,4.416-53.184,11.904
                  L172.516,6.023c-5.536-6.88-15.584-8.032-22.496-2.496c-6.88,5.536-8,15.584-2.496,22.496l28.096,35.136
                  c-28.832,23.456-47.616,58.784-47.616,98.848h256C384.004,119.687,364.996,84.167,335.876,60.711z M224.004,112.007
                  c-8.832,0-16-7.168-16-16s7.168-16,16-16s16,7.168,16,16S232.836,112.007,224.004,112.007z M288.004,112.007
                  c-8.832,0-16-7.168-16-16s7.168-16,16-16s16,7.168,16,16S296.836,112.007,288.004,112.007z"
                  />
                </g>
              </svg>
              {{ 'qrBased_instructionStep1' | translate : branchService.details.locale }}
            </p>
          </div>
        </div>
        <hr [ngStyle]="{ 'border-color': printerQueue.labelColor }" />
        <div class="printer-qr-instructions-step" [ngStyle]="{ color: printerQueue.labelColor }">
          <span [ngStyle]="{ color: printerQueue.labelColor }">2.</span>
          <span class="printer-qr-instructions-step-text" [ngStyle]="{ 'margin-bottom': 0 }">
            {{ 'qrBased_instructionStep2' | translate : branchService.details.locale }}
          </span>
        </div>
        <hr [ngStyle]="{ 'border-color': printerQueue.labelColor }" />
        <div class="printer-qr-instructions-step" [ngStyle]="{ color: printerQueue.labelColor }">
          <span [ngStyle]="{ color: printerQueue.labelColor }">3.</span>
          <span class="printer-qr-instructions-step-text">
            {{ 'qrBased_instructionStep3' | translate : branchService.details.locale }}
          </span>
        </div>
        <hr [ngStyle]="{ 'border-color': printerQueue.labelColor }" />
        <div class="printer-qr-instructions-step" [ngStyle]="{ color: printerQueue.labelColor }">
          <span [ngStyle]="{ color: printerQueue.labelColor }">4.</span>
          <span class="printer-qr-instructions-step-text">
            {{ 'qrBased_instructionStep4' | translate : branchService.details.locale }}
          </span>
        </div>
      </div>
    </div>
  </div>
  <app-image-carousel [images]="printerQueue.attachments"></app-image-carousel>
</div>
