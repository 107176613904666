/**
 * ! This component has been added to support Boots current setup of Qtime. They have no MDM to remotely
 * switch old counter displays to QtimeApp.net (QtimeAngular repo). This app (qtime-app-printer-angular)
 * should only be used for printer.
 *
 * ! Important, only use for Boots! Business logic specific to Boots is in this component.
 */

import { Component, NgZone, OnInit } from '@angular/core';
import { ActivatedRoute, Router } from '@angular/router';
import { animate, keyframes, style, transition, trigger } from '@angular/animations';
import Swal from 'sweetalert2';

import {
  BranchService,
  DataService,
  EmployeeConsoleEndpointsService,
  GlobalVariables,
  MonitorScreen,
} from '../common';
import { AuthButtonService } from '../auth-button';
import { NewTokenData, PusherService } from '../common/_services/pusher.service';
import { CounterCustomization } from './../common/_types/counter-customization';

@Component({
  selector: 'app-monitor-screen',
  templateUrl: './monitor-screen.component.html',
  styleUrls: ['./monitor-screen.component.scss'],
  providers: [EmployeeConsoleEndpointsService],
  animations: [
    trigger('tokenUpdate', [
      transition(
        '* => active',
        animate(
          4000,
          keyframes([
            style({ opacity: '0.0' }),
            style({ opacity: '1.0' }),
            style({ opacity: '0.0' }),
            style({ opacity: '1.0' }),
            style({ opacity: '0.0' }),
            style({ opacity: '1.0' }),
          ])
        )
      ),
    ]),
  ],
})
export class MonitorScreenComponent implements OnInit {
  public monitor: MonitorScreen;
  public monitorItems: any[] = [];
  public currentTokenNumber = '';
  public tokenAnimationFlag = 'inactive';
  public customization: CounterCustomization;

  counterId;
  branchQueueId;
  loggedIn;
  animateFlag;
  logoPath;
  unSubscribeCable;

  constructor(
    private router: Router,
    private route: ActivatedRoute,
    private dataService: DataService,
    private authButtonService: AuthButtonService,
    private employeeService: EmployeeConsoleEndpointsService,
    public branchService: BranchService,
    private zone: NgZone,
    private pusherService: PusherService
  ) {
    this.validateLogin();
  }

  refreshPage() {
    location.reload();
  }

  ngOnInit() {
    if (this.loggedIn) {
      this.routeParams();
      this.loadData();
      this.initPusherSubscription();

      this.zone.run(() => {
        console.log('enabled page re-render');
      });
    }
  }

  ngOnDestroy() {
    this.monitor = null;
    this.counterId = null;
    this.branchQueueId = null;
    this.loggedIn = null;
    this.animateFlag = null;
    this.pusherService.tokenBroadcastChannel.disconnect();
  }

  loadData() {
    this.employeeService.getMontiorScreenData().subscribe(
      (res: any) => {
        const data = res?.data as MonitorScreen;
        const included = res.included;

        this.monitor = data;
        this.loadCustomization();
        const { attributes } = data;

        const includedType = attributes.itemType === 'counter' ? 'counters' : 'branchQueues';

        this.monitorItems = included
          .filter((it) => it.type === includedType)
          .map((it) => ({ id: it.id, name: it.attributes.name }));

        this.currentTokenNumber =
          included
            .filter((it) => it.type === includedType && !!it?.attributes?.currentTokenNumber)
            .map((it) => it.attributes.currentTokenNumber)
            .sort((a, b) => +a.tokeNumber - +b.tokenNumber)[0]?.tokenNumber || '';
      },
      (error) => {
        Swal.fire({
          text: this.errorMessage(error),
          icon: 'warning',
          allowOutsideClick: false,
          showCancelButton: false,
          allowEscapeKey: false,
          confirmButtonColor: '#3085d6',
          confirmButtonText: 'Refresh the page!',
        }).then(() => {
          if (error && error[0].status == '401') {
            this.dataService.clearData();
            this.router.navigate(['login']);
          } else {
            location.reload();
          }
        });
      }
    );
  }

  // uses customization of counter display because of same layout
  // (token_display has same layout as counter_display)
  loadCustomization() {
    this.employeeService
      .getCounterDisplayCustomization()
      .subscribe((res: any) => (this.customization = res?.data?.attributes));
  }

  initPusherSubscription() {
    this.pusherService.disconnectAllChannels();
    const { branchId } = this.route.snapshot.params;
    this.pusherService.connectTokenBroadcast(branchId, this.handleNewTokenData);
  }

  handleNewTokenData = (payload: NewTokenData) => {
    if (!payload?.data?.id || !this.monitor?.id) return;
    const { counterId, branchQueueId, status, totalPeopleInQueue, tokenNumber } = payload.data.attributes;
    const { playTokenFetchSound, itemType, playSoundOnAllBranchQueues } = this.monitor.attributes;

    const isCounterItems = itemType === 'counter';
    const matchId = isCounterItems ? counterId : branchQueueId;
    const hasMonitorItemMatch = this.monitorItems.some((it) => it.id == matchId);

    // new pending
    if (status === 'pending' && playTokenFetchSound && totalPeopleInQueue === 1) {
      const { newToken } = this.branchService.soundEffects;
      this.branchService.playTokenEventSound(newToken);

      // next token for serving
    } else if (hasMonitorItemMatch && status === 'serving') {
      this.currentTokenNumber = `${tokenNumber}`;
      this.tokenAnimationFlag = 'active';
      this.branchService.playTokenEventSound(null, tokenNumber);

      // play sound on all branch queues
    } else if (playSoundOnAllBranchQueues && status === 'serving') {
      this.branchService.playTokenEventSound(null, tokenNumber);
    }
  };

  validateLogin() {
    this.loggedIn = this.dataService.sessionData();

    if (!this.loggedIn) {
      this.router.navigate(['/login']);
    }

    this.authButtonService.login();
  }

  routeParams() {
    this.route.params.subscribe((params) => {
      this.counterId = +params['id'];
      this.branchQueueId = +params['branchQueueId'];
    });
  }

  animationDone($event) {
    this.tokenAnimationFlag = 'inactive';
  }

  playTokenAnimation() {
    this.tokenAnimationFlag = 'active';
  }

  hasCurrentTokenNumber() {
    return this.currentTokenNumber;
  }

  errorMessage(error) {
    let message;

    if (error?.[0]?.status == '404') {
      message = GlobalVariables.ERROR_MESSAGE_404;
    } else if (error?.[0]?.message) {
      message = error[0].message;
    } else {
      message = GlobalVariables.DEFAULT_ERROR_MESSAGE;
    }

    return message;
  }
}
