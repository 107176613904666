import { BranchQueue } from './branch-queue'
import { Token } from './token'

export class Counter {
  DEFAULT_TOKEN_FONT_FAMILY = 'AG Book Rounded BQ';
  DEFAULT_COUNTER_FONT_FAMILY = 'Open Sans';
  DEFAULT_FONT_COLOR = '#fff';
  DEFAULT_BACKGROUND_COLOR = '#004795';
  DEFAULT_TOKEN_FONT_SIZE = 240;
  DEFAULT_COUNTER_FONT_SIZE = 110;

  public id;
  public name;
  public customName;
  public currentQueue;
  public currentlyServing: Token;
  public value;
  public label;
  public animationFlag;
  public tokenFontFamily;
  public counterFontFamily;
  public fontColor;
  public backgroundColor;
  public tokenFontSize;
  public counterFontSize;
  public sound;

  constructor() {
    this.currentlyServing = new Token();
    this.animationFlag = false;
    this.tokenFontFamily = this.DEFAULT_TOKEN_FONT_FAMILY;
    this.counterFontFamily = this.DEFAULT_COUNTER_FONT_FAMILY;
    this.fontColor = this.DEFAULT_FONT_COLOR;
    this.backgroundColor = this.DEFAULT_BACKGROUND_COLOR;
    this.tokenFontSize = this.DEFAULT_TOKEN_FONT_SIZE;
    this.counterFontSize = this.DEFAULT_COUNTER_FONT_SIZE;
  }

  setCounterData(data) {
    this.id = data.id;
    this.name = data.attributes.name;
    this.customName = data.attributes.customName;
    this.value = this.id;
    this.label = this.name;
    this.sound = data.attributes.sound;
    this.setFontStyles(data);
    this.setFontSizes(data);

    if(data.attributes) {
      this.setCurrentlyServing(data.attributes.currentTokenNumber)
    }

    this.currentQueue = new BranchQueue();
  }

  setFontStyles(data) {
    this.tokenFontFamily = data.attributes.tokenFontFamily || this.DEFAULT_TOKEN_FONT_FAMILY;
    this.counterFontFamily = data.attributes.counterFontFamily || this.DEFAULT_COUNTER_FONT_FAMILY;
    this.fontColor = data.attributes.fontColor || this.DEFAULT_FONT_COLOR;
    this.backgroundColor = data.attributes.backgroundColor || this.DEFAULT_BACKGROUND_COLOR;
  }

  setFontSizes(data) {
    this.tokenFontSize = data.attributes.tokenFontSize || this.DEFAULT_TOKEN_FONT_SIZE;
    this.counterFontSize = data.attributes.counterFontSize || this.DEFAULT_COUNTER_FONT_SIZE
  }

  setCurrentlyServing(data) {
    if(data) {
      this.currentlyServing.setTokenSerializedData(data);
    } else {
      if(!this.currentlyServing.tokenNumber) {
        this.currentlyServing.tokenNumber = '000';
      }
    }
  }

  setBranchQueue(data) {
    this.currentQueue.setBranchQueueData(data);
  }
}
