import { Component } from '@angular/core';
import { Router } from '@angular/router';

import { AuthButtonService } from '../auth-button/index';
import {
  DataService,
  AuthEndpointsService,
  GlobalVariables,
  HeaderService,
} from '../common/index';

@Component({
  selector: 'logout',
  template: '<p></p>',
  providers: [],
})
export class LogoutComponent {
  constructor(
    private router: Router,
    private authButtonService: AuthButtonService,
    private dataService: DataService,
    private authEndpointsService: AuthEndpointsService,
    private headerService: HeaderService
  ) {
    let loggedIn = this.dataService.sessionData();

    if (loggedIn) {
      this.authEndpointsService.logout().subscribe(
        (data) => {
          this.dataService.deleteSessionData();
          this.dataService.clearData();
          this.headerService.showLogout(false);

          this.router.navigate(['/login']);
        },
        (error) => {
          this.dataService.deleteSessionData();
          this.dataService.clearData();

          this.router.navigate(['/login']);
        }
      );
    } else {
      this.router.navigate(['/']);
    }
  }
}
