import { MoodyEnum } from './mood';

export class Token {
  public id;
  public tokenNumber;
  public createdAt: string;
  public status: string;
  public waitTime: number;
  public servingTime: number;
  public mood: MoodyEnum;
  public originType: string;
  public branchQueueId?: number;
  public branchQueueName?: string;

  setTokenData(data) {
    if (data) {
      if (data.attributes) {
        this.id = data.id;
        this.tokenNumber = data.attributes.tokenNumber;
        this.createdAt = data.attributes.createdAt;
        this.waitTime = Math.round(
          Math.abs(
            (new Date().getTime() - new Date(this.createdAt).getTime()) / 1000
          )
        );
        this.status = data.attributes.status;
      } else {
        this.id = data.id;
        this.tokenNumber = data.token_number;
        this.status = data.status;
      }
    } else {
      if (!this.id) {
        this.id = 0;
        this.tokenNumber = 0;
      }
    }
  }

  setTokenSerializedData(data) {
    if (data) {
      this.id = data.id;
      this.tokenNumber = data.tokenNumber;
      this.status = data.status;
      this.originType = data.originType;
      this.branchQueueId = data.branchQueueId;
      this.branchQueueName = data.branchQueueName;
    } else {
      this.id = undefined;
      this.tokenNumber = undefined;
    }
  }

  setMood(branchMoodTimer) {
    if (this.waitTime < branchMoodTimer.happy) {
      this.mood = MoodyEnum.happy;
    } else if (this.waitTime < branchMoodTimer.neutral) {
      this.mood = MoodyEnum.neutral;
    } else {
      this.mood = MoodyEnum.sad;
    }
  }
}
