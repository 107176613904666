import Swal, { SweetAlertOptions } from 'sweetalert2';
import { ConfirmationCustomization, GlobalVariables } from '../common/index';

export function printerDisconnectedAlert() {
  Swal.fire({
    title: 'Printer is not connected!',
    text: 'Please make sure the printer is connected properly to the system and wi-fi, and refresh the application',
    icon: 'warning',
    allowOutsideClick: false,
    showCancelButton: false,
    allowEscapeKey: false,
    confirmButtonColor: '#3085d6',
    confirmButtonText: 'Refresh application',
    showCloseButton: true,
  }).then(() => {
    location.reload();
  });
}

export function printerNoPaperAlert() {
  Swal.fire({
    title: 'No Paper!',
    text: 'Please make sure the printer has paper to print, and restart the printer, and refresh the application',
    icon: 'warning',
    allowOutsideClick: false,
    showCancelButton: false,
    allowEscapeKey: false,
    confirmButtonColor: '#3085d6',
    confirmButtonText: 'Refresh application',
    showCloseButton: true,
  }).then(() => {
    location.reload();
  });
}

export function busyPrinterAlert() {
  Swal.fire({
    title: 'Printer Warning!',
    text: 'Restart printer and refresh the application. Make sure that eror light is no blinking red',
    icon: 'warning',
    allowOutsideClick: false,
    showCancelButton: false,
    allowEscapeKey: false,
    confirmButtonColor: '#3085d6',
    confirmButtonText: 'Refresh application',
    showCloseButton: true,
  }).then(() => {
    location.reload();
  });
}

export function errorAlert(error, onAuthError: () => void) {
  Swal.fire({
    text: errorMessage(error),
    icon: 'warning',
    allowOutsideClick: false,
    showCancelButton: false,
    allowEscapeKey: false,
    confirmButtonColor: '#3085d6',
    confirmButtonText: 'Refresh the page!',
    showCloseButton: true,
    timer: undefined,
  }).then(() => {
    if (error?.[0]?.status == '401') onAuthError?.();
    else location.reload();
  });
}

export function printerConnectedAlert() {
  Swal.fire({
    html: '<h3> Printer connected! </h3>',
    icon: 'success',
    timer: 1500,
    allowOutsideClick: true,
    allowEscapeKey: true,
    confirmButtonColor: '#3085d6',
    confirmButtonText: 'Ok',
    showCloseButton: true,
  });
}

export function errorMessage(error) {
  if (!error) return GlobalVariables.DEFAULT_ERROR_MESSAGE;

  let message;

  if (error[0]?.status == '404') {
    message = GlobalVariables.ERROR_MESSAGE_404;
  } else if (error[0]?.message) {
    message = error[0].message;
  } else {
    message = GlobalVariables.DEFAULT_ERROR_MESSAGE;
  }

  return message;
}

export function isDesktop() {
  return !/Android|webOS|iPhone|iPad|iPod|BlackBerry|IEMobile|Opera Mini/i.test(
    navigator.userAgent
  );
}

export function makeConfirmationSwal(
  customization: ConfirmationCustomization
): SweetAlertOptions {
  const { htmlText, yesText, noText } = customization;

  const swalConfig: SweetAlertOptions = {
    html: `<h2>${htmlText}</h2>`,
    allowEscapeKey: false,
    confirmButtonColor: '#4DCE57',
    cancelButtonColor: '#516465',
    timer: 6000,
    showConfirmButton: false,
    showCancelButton: false,
  };

  if (yesText) {
    swalConfig.confirmButtonText = yesText;
    swalConfig.showConfirmButton = true;
  }

  if (noText) {
    swalConfig.cancelButtonText = noText;
    swalConfig.showCancelButton = true;
  }

  return swalConfig;
}
