<div class="ticket-modal-bg">
  <div
    [ngStyle]="{
      'font-family': customization.tokenFontFamily,
      background: customization.tokenBackgroundColor,
      color: customization.tokenFontColor
    }"
    class="ticket-modal"
  >
    <h1>{{ 'welcomeTo' | translate : branchService.details.locale }}</h1>
    <div *ngIf="this.logo" class="company-logo">
      <img src="{{ this.logo }}" alt="logo" />
    </div>
    <h2>{{ 'printingToken' | translate : branchService.details.locale }}</h2>
    <div *ngIf="!this.loading" class="values-wrapper">
      <h2 class="m-0">{{ 'yourQueueNumber' | translate : branchService.details.locale }}</h2>
      <p class="token-number">{{ tokenNumber }}</p>
    </div>
    <div *ngIf="this.loading" class="values-wrapper">
      <span><i class="fa fa-spinner fa-pulse fa-fw fa-5x"></i></span>
    </div>
  </div>
</div>
