import { Component, OnInit } from '@angular/core';
import { Router } from '@angular/router';
import Swal from 'sweetalert2';

import { AuthButtonService } from '../auth-button/index';
import {
  AuthEndpointsService,
  DataService,
  HeaderService,
} from '../common/index';

@Component({
  selector: 'login',
  templateUrl: './login-page.component.html',
  styleUrls: ['./login-page.component.scss'],
})
export class LoginPageComponent implements OnInit {
  formData: any = {};

  constructor(
    private router: Router,
    private authButtonService: AuthButtonService,
    private authEndpointsService: AuthEndpointsService,
    private dataService: DataService,
    private headerService: HeaderService
  ) {
    let loggedIn = this.dataService.sessionData();

    if (loggedIn) {
      this.headerService.showLogout(true);
      this.router.navigate(['/main-page']);
    }
  }

  ngOnInit() {}

  submitted = false;
  onSubmit() {
    this.authEndpointsService.login(this.formData).subscribe(
      (data: any) => {
        let loginData = data.data;

        this.dataService.setSessionData(loginData.attributes.accessToken);
        this.dataService.setCompanyLogo(loginData.attributes.logo);
        if (loginData.relationships) {
          this.dataService.setBranchData(
            loginData.relationships.branch.data.id
          );
        } else {
          this.authEndpointsService.logout();

          this.dataService.clearData();
          let message = 'Invalid Data Error: Please login again';
          this.alertMessage(message);
        }

        this.authButtonService.login();
        this.headerService.showLogout(true);
        this.router.navigate(['/main-page']);
      },
      (res) => {
        let message = 'Invalid Username and/or Password';

        if (res.error.errors[0]) {
          message = res.error.errors[0].message;
        }

        this.alertMessage(message, res.error.errors);
      }
    );
  }

  alertMessage(message, errors = null) {
    Swal.fire({
      text: message,
      icon: 'warning',
      allowOutsideClick: false,
      showCancelButton: false,
      allowEscapeKey: false,
      confirmButtonColor: '#3085d6',
      confirmButtonText: 'Continue!',
    }).then(() => {
      if (errors[0] && errors[0].status == '401') {
        this.dataService.clearData();
        this.router.navigate(['login']);
      } else {
        location.reload();
      }
    });
  }
}
