import { Pipe, PipeTransform } from '@angular/core';

@Pipe({
  name: 'secondsToDateTime'
})

export class SecondsToDateTime implements PipeTransform {
  transform(value) {
    if(value == undefined || parseInt(value) == 0) {
      return new Date(1970, 0, 1).setSeconds(0);
    }

    return new Date(1970, 0, 1).setSeconds(value);
  }
}
