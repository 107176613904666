import { Component, OnInit } from '@angular/core';
import { PrinterEndpointsService, PrintService } from '../../common/index';
import { QueuePrinterComponent } from '../queue-printer.component';

@Component({
  selector: 'printer-primary-queue-in-focus',
  templateUrl: './queue-printer-primary-queue-in-focus.component.html',
  styleUrls: ['./queue-printer-primary-queue-in-focus.component.scss'],
  providers: [PrinterEndpointsService, PrintService],
})
export class QueuePrinterPrimaryQueueInFocusComponent
  extends QueuePrinterComponent
  implements OnInit
{
  viewType = 'primary_queue_in_focus';

  ngOnInit() {
    super.ngOnInit();
    super.loadPrinter();
  }

  refreshPage() {
    location.reload();
  }
}
