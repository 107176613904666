<nav class="navbar navbar-main">
  <div class="container">
    <div class="navbar-header">
      Qtime Printer (.com)
      <span class="navbar-header-version">v{{ version }}</span>
    </div>
    <div *ngIf="showLogout" class="nav navbar-nav navbar-right">
      <div class="nav-branch-name">{{ branchService.details.name }}</div>
      <a href="{{ qtimeAppUrl }}"> To Qtime App </a>
      <a (click)="logoutSession()"> Logout </a>
    </div>
  </div>
</nav>
