import { Component, OnInit } from '@angular/core';
import { PrinterEndpointsService, PrintService } from '../../common/index';
import { QueuePrinterComponent } from '../queue-printer.component';

@Component({
  selector: 'printer-vrt-non-marketing',
  templateUrl: './queue-printer-vrt-non-marketing.component.html',
  styleUrls: ['./queue-printer-vrt-non-marketing.component.scss'],
  providers: [PrinterEndpointsService, PrintService],
})
export class QueuePrinterVerticalNonMarketingComponent
  extends QueuePrinterComponent
  implements OnInit
{
  viewType = 'non_marketing_vertical_view';

  ngOnInit() {
    super.ngOnInit();
    super.loadPrinter();
  }

  refreshPage() {
    location.reload();
  }
}
