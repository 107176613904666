import { Component, OnInit } from '@angular/core';
import { PrinterEndpointsService, PrintService } from '../../common/index';
import { QueuePrinterComponent } from '../queue-printer.component';

@Component({
  selector: 'printer-horz',
  templateUrl: './queue-printer-extra-queues.component.html',
  styleUrls: ['./queue-printer-extra-queues.component.scss'],
  providers: [PrinterEndpointsService, PrintService],
})
export class QueuePrinterExtraQueuesComponent
  extends QueuePrinterComponent
  implements OnInit
{
  viewType = 'extra_queues';

  ngOnInit() {
    super.ngOnInit();
    super.loadPrinter();
  }

  setQrSize() {
    if (this.printerQueue) this.printerQueue.qrSize = 100;
  }

  refreshPage() {
    location.reload();
  }

  checkToken(queue) {
    return this.showToken;
  }

  getBackground(printerQueue) {
    return (
      'linear-gradient(to right, ' +
      printerQueue.qrBackgroundColor1 +
      ',' +
      printerQueue.qrBackgroundColor2 +
      ')'
    );
  }
}
