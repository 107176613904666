import { Injectable } from '@angular/core';
import { HttpClient, HttpHeaders } from '@angular/common/http';

import { GlobalVariables } from '../global';
import { DataService } from './data.service';

@Injectable()
export class MainPageEndpointsService {
  private basicUrl = GlobalVariables.BASE_API_URL + '/api/v1/branches';
  constructor(private http: HttpClient, private dataService: DataService) {}

  getMonitorsData() {
    const headers = this.defaultHeaders();
    const options = { headers };
    const url = `${this.basicUrl}/${this.dataService.branchData()}/monitor_screens`;

    return this.http.get(url, options);
  }

  getPrintersData() {
    const headers = this.defaultHeaders();
    const options = { headers };
    let url = this.basicUrl + '/' + this.dataService.branchData() + '/printers';

    return this.http.get(url, options);
  }

  getQueuesData() {
    const headers = this.defaultHeaders();
    const options = { headers };

    let url = `${this.basicUrl}/${this.dataService.branchData()}/branch_queues`;

    return this.http.get(url, options);
  }

  getCountersData(queue) {
    const headers = this.defaultHeaders();
    const options = { headers };
    let url =
      this.basicUrl + '/' + this.dataService.branchData() + '/branch_queues/' + queue.id + '?include=counters';

    return this.http.get(url, options);
  }

  defaultHeaders() {
    let headers = new HttpHeaders({ 'Content-Type': 'application/json' });
    headers = headers.append('ACCESS-TOKEN', this.dataService.sessionData());
    return headers;
  }
}
