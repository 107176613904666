import { BranchQueue } from './branch-queue';

// TODO refactor and clean up this class (maybe just scrap it)
export interface PrinterQueue {
  branchQueueId: number;
  confirmationRequired: boolean;
  displayName: string;
  id: number;
}

export type PrinterViewType =
  | 'horizontal'
  | 'vertical'
  | 'qr_based'
  | 'extra_queues'
  | 'qr_in_focus';

export interface PrinterAttributes {
  id: string;
  attachments: Array<string>;
  backgroundColor: string;
  bluetoothAddress: string;
  buttonBackgroundColor: string;
  buttonBorderColor: string;
  buttonTextColor: string;
  enableQrCode: boolean;
  ipAddress: string;
  labelColor: string;
  logo: string;
  name: string;
  printerQueues: Array<PrinterQueue>;
  qrCode: string;
  showLogo: boolean;
  tokenCustomization: {
    background_color: string;
    created_at: string;
    font_color: string;
    font_family: string;
    id: number;
    token_customizable_id: number;
    token_customizable_type: 'Branch';
    updated_at: string;
  };
  view: PrinterViewType;
  printerType: 'ticket_less' | 'ticket_based';
}

export interface ConfirmationCustomization {
  text: string;
  yesText: string;
  noText: string;
  yesClickedText: string;
  noClickedText: string;
  htmlText: string;
  yesClickedHtmlText: string;
  noClickedHtmlText: string;
  printOnYes: boolean;
}

export interface PrinterQueue {
  id: number;
  branchQueueId: number;
  displayName: string;
  backgroundColor: string;
  confirmationRequired: boolean;
  subtext: string;
}

function formatText(text: string): string {
  if (!text) return '';
  return text.split('\\n').reduce((a, b) => a + '<br />' + b);
}

export class Printer {
  public id;
  public name;
  public logo;
  public branchQueue: Array<BranchQueue>;
  public value;
  public label;
  public qrCodeText;
  public qrCodeEnabled;
  public ipAddress;
  public qrSize;
  public attachments;
  public viewType;
  public backgroundColor;
  public buttonBackgroundColor;
  public buttonTextColor;
  public labelColor;
  public buttonBorderColor;
  public tokenFontColor;
  public tokenFontFamily;
  public tokenBackgroundColor;
  public qrBackgroundColor1;
  public qrBackgroundColor2;
  public qrCodeColor;
  public qrTextColor;
  public carouselActiveColor;
  public carouselInactiveColor;
  public displayTitle: string;
  public printerType: string;
  public printerQueues: PrinterQueue[] = [];
  public fontFamily: string;
  public textTransform: string;
  public playSounds: boolean = false;

  public showLogo: boolean;
  public hasConfirmation: boolean;

  public confirmationCustomization: ConfirmationCustomization;

  setPrinterData(data, included = []) {
    const customization = included.find(
      (it) => it.type === 'printerCustomizations'
    );

    this.id = data.id;
    this.value = this.id;
    this.branchQueue = new Array<BranchQueue>();
    this.showLogo = data.attributes.showLogo;
    this.name = data.attributes.name;
    this.label = this.name;

    this.qrCodeText = data.attributes.qrCode;
    this.qrCodeEnabled = data.attributes.enableQrCode;
    this.ipAddress = data.attributes.ipAddress;
    this.attachments = data.attributes.attachments;
    this.viewType = data.attributes.view;
    this.playSounds = !!data.attributes.playSounds;

    if (customization) this.setCustomizationData(customization);

    this.tokenFontColor =
      data?.attributes?.tokenCustomization?.font_color || '';
    this.tokenFontFamily =
      data?.attributes?.tokenCustomization?.font_family || '';
    this.tokenBackgroundColor =
      data?.attributes?.tokenCustomization?.background_color || '';
    this.displayTitle = data?.attributes?.displayTitle || '';
    this.printerType = data?.attributes?.printerType || '';

    this.printerQueues = data.attributes.printerQueues;
  }

  setQueuesData(data) {
    data.forEach((it) => {
      const printerQueue = this.printerQueues.find(
        (printerQueue) => it.id == printerQueue.branchQueueId
      );

      const queue = new BranchQueue();
      queue.setBranchQueueData(it);
      if (printerQueue) queue.applyPrinterQueuesData(printerQueue);

      this.branchQueue.push(queue);
    });
  }

  setCustomizationData(data) {
    const { attributes } = data;
    if (!attributes) return;

    this.labelColor = attributes.labelColor;
    this.buttonBorderColor = attributes.buttonBorderColor;
    this.backgroundColor = attributes.backgroundColor;
    this.buttonBackgroundColor = attributes.buttonColor;
    this.buttonTextColor = attributes.buttonTextColor;
    this.qrBackgroundColor1 = attributes.qrBackgroundColor1;
    this.qrBackgroundColor2 = attributes.qrBackgroundColor2;
    this.qrCodeColor = attributes.qrCodeColor;
    this.qrTextColor = attributes.qrTextColor;
    this.carouselActiveColor = attributes.carouselActiveColor;
    this.carouselInactiveColor = attributes.carouselInactiveColor;
    this.fontFamily = attributes.fontFamily;
    this.textTransform = attributes.textTransform;
    this.hasConfirmation = attributes.confirmation;

    this.confirmationCustomization = {
      text: attributes.confirmationText || 'Bekreft at du vil trekke en kølapp',
      yesText: attributes.yesText || '',
      noText: attributes.noText || '',
      noClickedText: attributes.noClickedText || '',
      yesClickedText: attributes.yesClickedText || '',
      htmlText: formatText(attributes.confirmationText),
      yesClickedHtmlText: formatText(attributes.yesClickedText),
      noClickedHtmlText: formatText(attributes.noClickedText),
      printOnYes: attributes.printOnYes,
    };
  }

  setAttachmentsImage(data) {
    if (data.attributes.attachments) {
      this.attachments = data.attributes.attachments;
    }
  }
}

export class QrCode {
  public isBannerImage;
  public animationName;
  public qrClicked;
  public qrForeground;
  public qrBackground;

  constructor() {
    this.isBannerImage = true;
    this.qrClicked = false;
    this.qrForeground = this.defaultQrForeground();
    this.qrBackground = this.defaultQrBackground();
  }

  defaultQrForeground() {
    return '#000000';
  }

  defaultQrBackground() {
    return '#f4f5f6';
  }
}
