import { Component } from '@angular/core';
import { version } from '../../package.json';
import '../types/global';
import { BranchService } from './common';

@Component({
  selector: 'app-root',
  templateUrl: './app.component.html',
})
export class AppComponent {
  constructor(branchService: BranchService) {
    this.startRefreshPageLoop();
    console.log(version);
    window?.Android?.sendMessage?.('test code');
    branchService.init();
  }

  startRefreshPageLoop() {
    const interval = 1000 * 60 * 60; // every hour

    (function loop() {
      let now = new Date();

      if (now.getHours() === 1) {
        const refreshedDate = window.localStorage.getItem('refreshedDate');

        if (
          !refreshedDate ||
          isNaN(+refreshedDate) ||
          +refreshedDate !== now.getDate()
        ) {
          const randomDelay = Math.floor(Math.random() * 60 * 60 * 1000);
          setTimeout(() => window.location.reload(true), randomDelay);
          window.localStorage.setItem('refreshedDate', now.getDate() + '');
        }
      }

      now = new Date();
      let delay = interval - (+now % interval); // exact ms to next minute interval
      setTimeout(loop, delay);
    })();
  }
}
