export const environment = {
  production: true,
  apiUrl: 'http://35.157.144.245',
  apiCableUrl: 'ws://35.157.144.245',
  mobileWebAppUrl: 'https://qtimeqr.com/',
  soundUrls: {
    newPendingToken:
      'https://qtime-prod.s3.eu-north-1.amazonaws.com/sound/newPendingToken.mp3',
    nextToken:
      'https://qtime-prod.s3.eu-north-1.amazonaws.com/sound/nextToken.mp3',
  },
  pusher: {
    app_key: '20e7f9d473b6d8686fce',
    app_cluster: 'eu',
  },
  sentry: {
    // safe to keep public according to docs due: https://docs.sentry.io/product/sentry-basics/dsn-explainer/
    dsn: 'https://9552598ed15e4526a58447fc2d047026@o4504672781008896.ingest.sentry.io/4504763116814336',
  },
  qtimeAppUrl: 'https://qtimeapp.net/',
};
