<main-header></main-header>

<div class='container'>
  <div class="login-wrapper">
    <div class='login-section'>
      <div class="user-dp">
      </div>
      <form (ngSubmit)='loginForm.form.valid && onSubmit()' #loginForm='ngForm' novalidate>
        <div class='form-group' [ngClass]="{ 'has-error': loginForm.submitted && !email.valid }">
          <label for='email'>Email</label>
          <div class="login-user">
            <input type='email' class='form-control' name='email' required [(ngModel)]='formData.email' #email='ngModel'>
            <span class="user-icon"></span>
            <div [hidden]='email.valid || email.pristine'>
              Email is required
            </div>
          </div>
        </div>
        <div class='form-group' [ngClass]="{ 'has-error': loginForm.submitted && !password.valid }">
          <label for='password'>Password</label>
          <div class="login-password">
            <input type='password' class='form-control' name='password' required [(ngModel)]='formData.password' #password='ngModel'>
            <span class="password-icon"></span>
            <div [hidden]='password.valid || password.pristine'>
              Password is required
            </div>
          </div>
        </div>
        <div class='form-group'>
          <button type='submit' class='btn btn-primary btn-block' [disabled]='!loginForm.form.valid'>Login</button>
        </div>
      </form>
    </div>
  </div>
</div>
