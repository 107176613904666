import { Component } from '@angular/core';
import { PrinterEndpointsService, PrintService } from '../../common/index';
import { QueuePrinterComponent } from '../queue-printer.component';

@Component({
  selector: 'printer-horz',
  templateUrl: './queue-printer-qr.component.html',
  styleUrls: ['./queue-printer-qr.component.scss'],
  providers: [PrinterEndpointsService, PrintService],
})
export class QueuePrinterQrComponent extends QueuePrinterComponent {
  viewType = 'extra_queues';

  ngOnInit() {
    super.ngOnInit();
    super.loadPrinter(null, false);
  }

  setQrSize() {
    if (typeof this.scrHeight !== 'number') return;
    if (this.printerQueue) {
      this.printerQueue.qrSize = (this.scrHeight / 5).toFixed(0);
    }
  }

  refresh() {
    location.reload();
  }
}
