<div id="printer-page-data" class="montain-bg-wrapper" (click)="printQueueSlip(queues)">
  <div class="top-section" [ngStyle]="{ background: printerQueue.backgroundColor }">
    <div class="container printer-header">
      <div class="logo-section">
        <img (click)="refreshPage()" id="company-logo-img" src="{{ printerQueue?.logo }}" alt="logo" />
      </div>
      <div *ngIf="printerQueue.qrCodeEnabled" class="right-qr qr-click-release">
        <div class="qr-wrapper">
          <qr-code
            class="qr-image"
            *ngIf="printerQueue.qrCodeText"
            [value]="[mobileWebAppUrl, printerQueue.qrCodeText].join('')"
            [size]="printerQueue.qrSize"
            [level]="'H'"
            [foreground]="qrCode.qrForeground"
            [background]="qrCode.qrBackground"
          ></qr-code>
        </div>
      </div>
    </div>
    <div class="container-fluid printer-banner">
      <app-image-carousel [images]="printerQueue.attachments"></app-image-carousel>
    </div>
    <div class="printer-bottom-section" [ngStyle]="{ background: printerQueue.backgroundColor }">
      <div *ngFor="let queue of queues" (touchstart)="showHover($event, queue)" (touchend)="hideHover($event, queue)">
        <div
          (click)="isDesktop() && handleQueueButtonClick(queue)"
          (touchstart)="!isDesktop() && handleQueueButtonClick(queue)"
          class="vrt-btn-main-container"
        >
          <p class="queue-label" [ngStyle]="{ color: printerQueue.labelColor }">
            {{ printerQueue?.displayTitle || queueLabel }}
          </p>
          <div class="blue-button" [ngStyle]="{ background: printerQueue.buttonBorderColor }">
            <span
              [ngClass]="{ 'hover-state': queue.hover }"
              [ngStyle]="{
                color: printerQueue.buttonTextColor,
                background: printerQueue.buttonBackgroundColor,
                'border-color': printerQueue.buttonBackgroundColor
              }"
              >{{ queue.name }}</span
            >
          </div>
        </div>
      </div>
    </div>
  </div>
</div>

<app-ticket-modal
  *ngIf="showModal"
  [logo]="branchService?.details?.logoOriginal"
  [customization]="{
    tokenFontFamily: printerQueue.tokenFontFamily,
    tokenBackgroundColor: printerQueue.tokenBackgroundColor,
    tokenFontColor: printerQueue.tokenFontColor
  }"
  [branchName]="branchService?.details?.name"
  [loading]="!showToken"
  [tokenNumber]="ticket?.tokenNumber"
>
</app-ticket-modal>
