import { Injectable } from "@angular/core";
@Injectable()
export class DataService {
  constructor() { }

  sessionData() {
    return localStorage.getItem('sessionToken');
  }

  setSessionData(token): void {
    localStorage.setItem('sessionToken', token);
  }

  deleteSessionData() {
    localStorage.removeItem('sessionToken');
  }

  CompanyLogo() {
    return localStorage.getItem('companyLogoPath');
  }

  setCompanyLogo(path): void {
    localStorage.setItem('companyLogoPath', path);
  }

  setBranchData(id): void {
    localStorage.setItem('branchData', id);
  }

  branchData() {
    return localStorage.getItem('branchData');
  }

  setSessionConsoleIds(hash): void {
    localStorage.setItem('consoleIds', JSON.stringify(hash));
  }

  sessionConsoleIds() {
    return JSON.parse(localStorage.getItem('consoleIds'));
  }

  clearData() {
    localStorage.clear();
  }
}
