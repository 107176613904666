import { Pipe, PipeTransform } from '@angular/core';
import { Locale } from '../_services/branch.service';
import { COMMON_TRANSLATIONS, TranslationKeys } from '../translations';

@Pipe({
  name: 'translate',
})
export class TranslatePipe implements PipeTransform {
  transform(key: keyof TranslationKeys, locale: Locale) {
    // TODO report via sentry if key is missing
    return COMMON_TRANSLATIONS[locale]?.[key] || key;
  }
}
