import { throwError as observableThrowError } from 'rxjs';
import { Injectable } from '@angular/core';
import { map, catchError } from 'rxjs/operators';
import { HttpClient, HttpHeaders } from '@angular/common/http';

import { GlobalVariables } from '../global';
import { DataService } from './data.service';

@Injectable()
export class AuthEndpointsService {
  private authUrl = GlobalVariables.BASE_API_URL + '/api/v1/branch_admin';
  constructor(private http: HttpClient, private dataService: DataService) {}

  login(formData) {
    const headers = this.defaultHeaders();
    const options = { headers };

    const loginUrl = this.authUrl + '/sign_in';

    return this.http.post(loginUrl, { account: formData }, options);
  }

  logout() {
    const headers = this.defaultHeaders();
    const options = { headers };

    let loginUrl = this.authUrl + '/sign_out';

    return this.http.delete(loginUrl, options);
  }

  defaultHeaders() {
    return new HttpHeaders({
      'Content-Type': 'application/json',
    });
  }
}
