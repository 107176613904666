import { Component, Input, OnChanges, OnInit } from '@angular/core';

@Component({
  selector: 'app-image-carousel',
  templateUrl: './image-carousel.component.html',
  styleUrls: ['./image-carousel.component.scss'],
})
export class ImageCarouselComponent implements OnInit, OnChanges {
  @Input() images = [];

  activeIndex = 0;
  carouselInterval = null;
  intervalSeconds = 30;

  constructor() {}

  ngOnInit(): void {
    if (this.images?.length > 1) this.startCarousel();
  }

  startCarousel() {
    setInterval(() => {
      this.activeIndex =
        this.images.length - 1 === this.activeIndex ? 0 : this.activeIndex + 1;
    }, this.intervalSeconds * 1000);
  }

  ngOnDestroy() {
    clearInterval(this.carouselInterval);
  }

  ngOnChanges(changes) {
    if (
      changes.images.currentValue?.length > 1 &&
      !changes.images.previousValue
    ) {
      this.startCarousel();
    }
  }
}
