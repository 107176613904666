import { forkJoin, throwError as observableThrowError } from 'rxjs';
import { Injectable } from '@angular/core';
import { map, catchError } from 'rxjs/operators';
import { HttpClient, HttpHeaders } from '@angular/common/http';
import { Howl } from 'howler';

import { GlobalVariables } from '../global';
import { DataService } from './data.service';

export type Locale = 'nb-NO' | 'sv-SE' | 'en-US';

export interface BranchSoundEffects {
  next_pending_token_sound: string;
  next_token_created_sound: string;
  printer_next_pending_token_sound: string;
  printer_next_token_created_sound: string;
}

export interface BranchSounds {
  data: {
    attributes: {
      audioAttachments: {
        tokenNumber: string;
        attachment: string;
      }[];
    };
  };
}

export interface BranchDetails {
  attachmentType: string;
  backgroundColor: string;
  companyName: string;
  fontColor: string;
  fontFamily: string;
  happy: number;
  neutral: number;
  logo: string;
  logoOriginal: string;
  name: string;
  ticketAttachment: string;
  ticketText: string;
  id: string;
  locale: Locale;
  playVoiceReads: boolean;
}

export const DEFAULT_SOUND_EFFECTS = {
  CALL_NEXT: GlobalVariables.CALL_NEXT_SOUND_URL,
  NEW_TOKEN: GlobalVariables.NEW_TOKEN_SOUND_URL,
};

const initalSoundEffects = {
  callNext: DEFAULT_SOUND_EFFECTS.CALL_NEXT,
  newToken: DEFAULT_SOUND_EFFECTS.NEW_TOKEN,
  printerCallNext: DEFAULT_SOUND_EFFECTS.CALL_NEXT,
  printerNewToken: DEFAULT_SOUND_EFFECTS.NEW_TOKEN,
};

@Injectable()
export class BranchService {
  private baseUrl = GlobalVariables.BASE_API_URL + '/api/v1/branches';

  public details: BranchDetails = {
    attachmentType: '',
    backgroundColor: '',
    companyName: '',
    fontColor: '',
    fontFamily: '',
    happy: -1,
    neutral: -1,
    logo: '',
    logoOriginal: '',
    name: '',
    ticketAttachment: '',
    ticketText: '',
    id: '',
    locale: 'en-US',
    playVoiceReads: false,
  };

  public sounds = {};
  public playVoiceReads = false;
  public soundEffects = { ...initalSoundEffects };

  constructor(private http: HttpClient, private dataService: DataService) {}

  init(id?: number | string) {
    this.getBranchDetails(id || this.dataService.branchData());
    this.fetchTokenSounds();
    this.fetchSoundEffects();
  }

  getBranchDetails(branchId) {
    if (this.details.id) return;

    const options = { headers: this.defaultHeaders() };
    const url = `${this.baseUrl}/${branchId}/details`;

    return this.http.get(url, options).subscribe(
      (res) => this.setBranchDetail(res),
      (err) => this.handleError(err, branchId)
    );
  }

  fetchSoundEffects() {
    const branchId = this.dataService.branchData?.();
    const options = { headers: this.defaultHeaders() };

    if (!branchId) return;

    const url = `${this.baseUrl}/${branchId}/sound_effects`;

    this.http.get<BranchSoundEffects>(url, options).subscribe(
      (soundEffects) => {
        if (!soundEffects) return;

        const {
          next_pending_token_sound,
          next_token_created_sound,
          printer_next_pending_token_sound,
          printer_next_token_created_sound,
        } = soundEffects || {};

        const { CALL_NEXT, NEW_TOKEN } = DEFAULT_SOUND_EFFECTS;

        this.soundEffects = {
          callNext: next_pending_token_sound || CALL_NEXT,
          newToken: next_token_created_sound || NEW_TOKEN,
          printerCallNext: printer_next_pending_token_sound || CALL_NEXT,
          printerNewToken: printer_next_token_created_sound || NEW_TOKEN,
        };
      },
      (err) => {
        console.error(err);
      }
    );
  }

  fetchTokenSounds() {
    const branchId = this.dataService.branchData?.();
    const options = { headers: this.defaultHeaders() };

    if (!branchId) return;
    if (Object.keys(this.sounds).length > 0) return;

    const url = `${this.baseUrl}/${branchId}/sound`;

    this.http.get<BranchSounds>(url, options).subscribe(
      (tokenSounds) => {
        const { data } = tokenSounds;
        const { audioAttachments } = data.attributes;

        this.sounds = {};
        audioAttachments.forEach((it) => (this.sounds[it.tokenNumber] = it.attachment));
      },
      (err) => {
        console.error(err);
      }
    );
  }

  setBranchDetail(detailResponse) {
    if (!detailResponse?.data?.attributes) return;

    const { id, attributes } = detailResponse.data;

    this.details = {
      ...attributes,
      id,
    };

    this.playVoiceReads = !!attributes.allowLanguage;
  }

  playTokenEventSound(soundFile?, tokenNumber?: string | number) {
    try {
      const tokenReadSound = this.sounds[tokenNumber];

      let soundToPlay = this.soundEffects.printerCallNext;

      if (soundFile) soundToPlay = soundFile;
      else if (this.playVoiceReads && tokenReadSound) soundToPlay = tokenReadSound;

      let sound: Howl;

      sound = new Howl({ src: [soundToPlay], autoplay: true });
      sound.play();
    } catch (e) {
      console.error(e);
    }
  }

  private handleError(err: Response | any, branchId) {
    if (!err?.json) {
      return observableThrowError([
        {
          status: '500',
          message: `unknown error in getBranchDetails with branchId: ${branchId}`,
          error: err,
        },
      ]);
    }
    return observableThrowError(err.json().errors);
  }

  defaultHeaders() {
    let headers = new HttpHeaders({ 'Content-Type': 'application/json' });
    headers = headers.append('ACCESS-TOKEN', this.dataService.sessionData());
    return headers;
  }
}
