import { Component, Input } from '@angular/core';
import { BranchService } from '../common';

export interface TicketModalCustomization {
  tokenFontFamily: string;
  tokenBackgroundColor: string;
  tokenFontColor: string;
}

@Component({
  selector: 'app-ticket-modal',
  templateUrl: './ticket-modal.component.html',
  styleUrls: ['./ticket-modal.component.scss'],
})
export class TicketModalComponent {
  @Input() customization: TicketModalCustomization;
  @Input() logo: string;
  @Input() loading: boolean;
  @Input() branchName: string;
  @Input() tokenNumber: string;

  constructor(public branchService: BranchService) {}
}
