import { Component, OnInit } from '@angular/core';
import { Router } from '@angular/router';
import Swal from 'sweetalert2';

import { HeaderService, DataService, BranchService, GlobalVariables } from '../common/index';

import { version } from '../../../package.json';

@Component({
  selector: 'main-header',
  templateUrl: './header.component.html',
  styleUrls: ['./header.component.scss'],
})
export class HeaderComponent implements OnInit {
  showLogout: boolean;
  loggedIn: boolean;
  qtimeAppUrl: string = GlobalVariables.QTIME_APP_URL;
  version: string = version;

  constructor(
    private router: Router,
    private headerService: HeaderService,
    private dataService: DataService,
    public branchService: BranchService
  ) {
    this.validateLogin();
    this.branchService.getBranchDetails(this.dataService.branchData());
  }

  validateLogin() {
    this.loggedIn = !!this.dataService.sessionData();

    if (this.loggedIn) {
      this.showLogout = true;
    }
  }

  ngOnInit() {
    this.headerService.showNavBarEmitter.subscribe((data) => {
      if (data !== null) {
        this.showLogout = data;
      }
    });
  }

  logoutSession() {
    Swal.fire({
      title: 'Are you sure?',
      text: 'You will be logged out of the system by pressing "yes".',
      icon: 'warning',
      allowOutsideClick: true,
      showCancelButton: true,
      allowEscapeKey: false,
      confirmButtonColor: '#009D4F',
      confirmButtonText: 'Yes',
      cancelButtonColor: '#E94B4B',
      cancelButtonText: 'Cancel',
    }).then((result) => {
      if (result.isConfirmed) {
        this.router.navigate(['/logout']);
      }
    });
  }
}
