<div id="printer-page-data" class="montain-bg-wrapper hrz-printer-wrapper" (click)="printQueueSlip(queues)">
  <div class="top-section" [ngStyle]="{ background: printerQueue.backgroundColor }">
    <div class="printer-header">
      <div class="logo-section">
        <img *ngIf="printerQueue.showLogo" (click)="refreshPage()" src="{{ printerQueue?.logo }}" alt="logo" />
      </div>
    </div>
    <div class="container-fluid printer-banner">
      <app-image-carousel [images]="printerQueue.attachments"></app-image-carousel>
    </div>
  </div>

  <div class="container-fluid hrz-printer-control-wrapper" [ngStyle]="{ background: printerQueue.backgroundColor }">
    <div #bottomSection class="hrz-printer-bottom-section">
      <div *ngIf="printerQueue.qrCodeEnabled" class="queue-label" [ngStyle]="{ color: printerQueue.labelColor }">
        <span [ngStyle]="{ background: printerQueue.backgroundColor }"> Klikk her for kølapp, eller scan QR kode her </span>
        <span [ngStyle]="{ 'border-bottom': 'solid 2px ' + printerQueue.labelColor }" class="queue-label-arrow-body"></span>
        <span [ngStyle]="{ 'border-left': 'solid 12px ' + printerQueue.labelColor }" class="queue-label-arrow-head"></span>
      </div>
      <div *ngIf="!printerQueue.qrCodeEnabled" class="queue-label no-qr" [ngStyle]="{ color: printerQueue.labelColor }">
        <span [ngStyle]="{ background: printerQueue.backgroundColor }">
          {{ printerQueue.displayTitle || 'Klikk her for kølapp' }}
        </span>
      </div>
      <div class="queue-buttons-container">
        <div class="blue-container" *ngFor="let queue of queues; let queueIndex = index">
          <div
            (click)="isDesktop() && handleQueueButtonClick(queue)"
            (touchstart)="!isDesktop() && handleQueueButtonClick(queue)"
            class="blue-button"
            [ngStyle]="{
              background: printerQueue.buttonBackgroundColor,
              borderColor: printerQueue.buttonBorderColor,
              fontSize: queueFontSizes[queueIndex] || '4.8vw',
              color: printerQueue.buttonTextColor
            }"
          >
            {{ queue.name }}
          </div>
        </div>
      </div>
    </div>
    <div *ngIf="printerQueue.qrCodeEnabled" class="right-qr qr-click-release">
      <qr-code
        *ngIf="printerQueue.qrCodeText"
        class="qr-image"
        [value]="[mobileWebAppUrl, printerQueue.qrCodeText].join('')"
        [size]="printerQueue.qrSize"
        [level]="'H'"
        [background]="'white'"
        (touchstart)="showHover($event)"
        (touchend)="hideHover($event)"
      ></qr-code>
    </div>
    <div class="clearfix"></div>
  </div>
</div>
<app-ticket-modal
  *ngIf="showModal"
  [logo]="branchService?.details?.logoOriginal"
  [customization]="{
    tokenFontFamily: printerQueue.tokenFontFamily,
    tokenBackgroundColor: printerQueue.tokenBackgroundColor,
    tokenFontColor: printerQueue.tokenFontColor
  }"
  [branchName]="branchService?.details?.name"
  [loading]="!showToken"
  [tokenNumber]="ticket?.tokenNumber"
>
</app-ticket-modal>
