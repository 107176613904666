<main-header></main-header>

<div class="container">
  <h1 class="main-heading pull-left">
    Select Device
    <div class="auto-redirect-input">
      <input
        class="form-check-input"
        type="checkbox"
        (click)="handleUseAutoRedirectChange()"
        [checked]="autoRedirectMode"
        id="auto-redirect-check"
      />
      <label class="form-check-label" for="auto-redirect-check"> Use Auto Redirect </label>
    </div>
  </h1>
  <div class="devices-outer-wrapper row">
    <div class="col-lg-3 col-sm-6 col-xs-12">
      <div class="device-wrapper">
        <p>Printer</p>
        <div class="img-block printer-icon"></div>
        <ng-select [options]="printers" noFilter="true" placeholder="Dropdown Options" (selected)="onPrinterSelect($event)">
        </ng-select>
      </div>

      <div class="device-wrapper" *ngIf="ipAddress && showPrinterIp">
        <form [formGroup]="ipAddress" (ngSubmit)="onPrinterSubmit(ipAddress)">
          <div class="form-group">
            <p>Ip Address:</p>
            <input
              type="text"
              class="ip-text-block"
              placeholder="IP ADDRESS"
              formControlName="ipValue"
              [pattern]="pattern"
            />
          </div>
          <button type="submit" class="btn btn-default ip-submit-button btn-block" [disabled]="!ipAddress.valid">Use</button>
        </form>
      </div>
    </div>

    <div class="col-lg-3 col-sm-6 col-xs-12">
      <div class="queue device-wrapper">
        <p>Single Counter Display</p>
        <div class="img-block display-icon"></div>
        <ng-select
          id="queue-display-id"
          [options]="queues"
          noFilter="true"
          placeholder="Dropdown Options"
          (selected)="onConsoleQueueSelect($event)"
        >
        </ng-select>
      </div>

      <div class="counter-roof" [ngClass]="{ clear: !isRoofCounter }">
        <p>Counter Display</p>
        <div class="img-block display-icon"></div>
        <ng-select
          id="queue-counter-display-id"
          *ngIf="queue"
          [options]="queue.counters"
          noFilter="true"
          placeholder="Dropdown Options"
          (selected)="loadRoofCounter(queue, $event)"
          (opened)="scrollQueueCounter($event)"
        >
        </ng-select>
      </div>
    </div>

    <div class="col-lg-3 col-md-3 col-sm-6 col-xs-12">
      <div class="device-wrapper">
        <p>
          Monitor
          <span class="deprecation-warning">(Deprecated, use .net instead)</span>
        </p>
        <div class="img-block monitor-icon"></div>
        <ng-select
          class="custom"
          [options]="monitors"
          noFilter="true"
          placeholder="Dropdown Options"
          (selected)="onMonitorSelect($event)"
        >
        </ng-select>
      </div>
    </div>
  </div>
</div>
