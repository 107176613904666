<div
  class="printer-vrt-non-marketing-container --queues-{{ queues?.length || 0 }}"
  [ngStyle]="{
    background: printerQueue.backgroundColor,
    fontFamily: printerQueue.fontFamily
  }"
>
  <img (click)="refreshPage()" src="{{ printerQueue?.logo }}" />
  <h1 *ngIf="printerQueue?.displayTitle">{{ printerQueue.displayTitle }}</h1>
  <div *ngIf="printerQueue.qrCodeEnabled" class="qr-wrapper">
    <qr-code
      class="qr-image"
      *ngIf="printerQueue.qrCodeText"
      [value]="[mobileWebAppUrl, printerQueue.qrCodeText].join('')"
      [size]="printerQueue.qrSize"
      [level]="'H'"
      [foreground]="qrCode.qrForeground"
      [background]="qrCode.qrBackground"
    ></qr-code>
  </div>
  <div class="printer-vrt-non-marketing-buttons">
    <button
      *ngFor="let queue of queues"
      (click)="isDesktop() && handleQueueButtonClick(queue)"
      (touchstart)="!isDesktop() && handleQueueButtonClick(queue)"
      [ngClass]="{ '--has-subtext': queue.subtext, '--gt-20': queue.name?.length > 20 }"
      [ngStyle]="{
        color: printerQueue.buttonTextColor,
        background: queue.backgroundColor || printerQueue.buttonBackgroundColor,
        textTransform: printerQueue.textTransform
      }"
    >
      {{ queue.name }}
      <span *ngIf="queue.subtext" class="printer-vrt-non-marketing-subtext"> {{ queue.subtext }} </span>
    </button>
  </div>
</div>

<app-ticket-modal
  *ngIf="showModal"
  [logo]="branchService?.details?.logoOriginal"
  [customization]="{
    tokenFontFamily: printerQueue.tokenFontFamily,
    tokenBackgroundColor: printerQueue.tokenBackgroundColor,
    tokenFontColor: printerQueue.tokenFontColor
  }"
  [branchName]="branchService?.details?.name"
  [loading]="!showToken"
  [tokenNumber]="ticket?.tokenNumber"
>
</app-ticket-modal>
