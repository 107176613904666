import { environment } from '../../environments/environment';

export const GlobalVariables = Object.freeze({
  BASE_API_URL: environment.apiUrl,
  BASE_CABLE_URL: environment.apiCableUrl,
  PUSHER_CONFIG: environment.pusher,
  UNUTHENTICATED: 'Please Log in to system first',
  MOBILE_WEB_APP_URL: environment.mobileWebAppUrl,
  DEFAULT_ERROR_MESSAGE: 'An error has occurred. Please refresh the page',
  ERROR_MESSAGE_404:
    'There was a problem in completing this request. Please try again',
  VIDEO_URL_OPTIONS: {
    loop: 1,
    controls: 0,
    rel: 0,
    showinfo: 0,
    modestbranding: 1,
    cc_load_policy: 0,
    iv_load_policy: 3,
    mute: 1,
    autoplay: 1,
  },
  CALL_NEXT_SOUND_URL: environment.soundUrls.nextToken,
  NEW_TOKEN_SOUND_URL: environment.soundUrls.newPendingToken,
  QTIME_APP_URL: environment.qtimeAppUrl,
});
