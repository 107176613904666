import { QueuePrinterPrimaryQueueInFocusComponent } from './queue-printer/queue-printer-primary-queue-in-focus/queue-printer-primary-queue-in-focus.component';
import { NgModule } from '@angular/core';
import { Routes, RouterModule } from '@angular/router';

import { LoginPageComponent } from 'app/login-page/login-page.component';
import { LogoutComponent } from 'app/logout/logout.component';
import { QueuePrinterHorizontalComponent } from 'app/queue-printer/queue-printer-horz/queue-printer-horz.component';
import { QueuePrinterExtraQueuesComponent } from 'app/queue-printer/queue-printer-extra-queues/queue-printer-extra-queues.component';
import { QueuePrinterVerticalComponent } from 'app/queue-printer/queue-printer-vrt/queue-printer-vrt.component';
import { QueuePrinterQrComponent } from 'app/queue-printer/queue-printer-qr/queue-printer-qr.component';
import { MainPageComponent } from 'app/main-page/main-page.component';
import { PageNotFoundComponent } from 'app/page-not-found/page-not-found.component';
import { RoofCounterComponent } from 'app/roof-counter/roof-counter.component';
import { QueuePrinterQrFocusComponent } from './queue-printer/queue-printer-qr-focus/queue-printer-qr-focus.component';
import { QueuePrinterVerticalNonMarketingComponent } from './queue-printer/queue-printer-vrt-non-marketing/queue-printer-vrt-non-marketing.component';
import { MonitorScreenComponent } from './monitor-screen/monitor-screen.component';

const routes: Routes = [
  { path: 'login', component: LoginPageComponent },
  { path: 'logout', component: LogoutComponent },
  { path: 'main-page', component: MainPageComponent },
  {
    path: 'branch/:branchId/printer/:id/vertical',
    component: QueuePrinterVerticalComponent,
    data: { viewType: 'vertical' },
  },
  {
    path: 'branch/:branchId/printer/:id/horizontal',
    component: QueuePrinterHorizontalComponent,
  },
  {
    path: 'branch/:branchId/printer/:id/extra_queues',
    component: QueuePrinterExtraQueuesComponent,
  },
  {
    path: 'branch/:branchId/printer/:id/qr_based',
    component: QueuePrinterQrComponent,
  },
  {
    path: 'branch/:branchId/printer/:id/qr_in_focus',
    component: QueuePrinterQrFocusComponent,
  },
  {
    path: 'branch/:branchId/printer/:id/non_marketing_vertical_view',
    component: QueuePrinterVerticalNonMarketingComponent,
  },
  {
    path: 'branch/:branchId/printer/:id/primary_queue_in_focus',
    component: QueuePrinterPrimaryQueueInFocusComponent,
  },
  { path: '', redirectTo: '/main-page', pathMatch: 'full' },
  {
    path: 'branch/:branchId/branch-queue/:branchQueueId/employee-console/:id',
    component: RoofCounterComponent,
  },
  {
    path: 'branch/:branchId/monitor-screen/:id',
    component: MonitorScreenComponent,
  },

  { path: '**', component: PageNotFoundComponent },
];

@NgModule({
  imports: [RouterModule.forRoot(routes, { relativeLinkResolution: 'legacy' })],
  exports: [RouterModule],
  providers: [],
})
export class AppRoutingModule {}
