<div class="monitor-screen-container" [ngStyle]="{ background: customization?.backgroundColor }" (click)="refreshPage()">
  <div
    class="monitor-screen-item-container"
    *ngIf="!!currentTokenNumber && monitor?.id && customization?.backgroundColor; else noToken"
  >
    <div
      class="item-name-container"
      [ngStyle]="{
        background: customization.fontColor,
        'font-family': customization.counterFontFamily,
        color: customization.backgroundColor,
        'font-size': customization.counterFontSize + 'px'
      }"
    >
      <div
        [ngStyle]="{
          background: customization.fontColor
        }"
        class="before"
      ></div>
      <div
        class="item-name"
        [ngStyle]="{ color: customization?.backgroundColor, 'font-size': customization?.counterFontSize + 'px' }"
      >
        {{ monitor?.attributes?.name }}
      </div>
      <div
        [ngStyle]="{
          background: customization.fontColor
        }"
        class="after"
      ></div>
    </div>
    <div
      [@tokenUpdate]="tokenAnimationFlag"
      (@tokenUpdate.done)="animationDone($event)"
      class="token-number"
      [ngStyle]="{
        color: customization.fontColor,
        'font-family': customization.tokenFontFamily,
        'font-size': customization.tokenFontSize + 'px'
      }"
    >
      {{ currentTokenNumber }}
    </div>
  </div>
  <ng-template #noToken>
    <div class="idle-container" [ngStyle]="{ background: customization?.backgroundColor }">
      <img src="{{ branchService.details?.logoOriginal }}" alt="" />
    </div>
  </ng-template>
</div>
