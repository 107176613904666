import { BrowserModule } from '@angular/platform-browser';
import { BrowserAnimationsModule } from '@angular/platform-browser/animations';
import {
  HammerGestureConfig,
  HAMMER_GESTURE_CONFIG,
} from '@angular/platform-browser';
import {
  NgModule,
  Injectable,
  APP_INITIALIZER,
  ErrorHandler,
} from '@angular/core';
import { FormsModule, ReactiveFormsModule } from '@angular/forms';
import { HttpClientModule } from '@angular/common/http';
import { SelectModule } from 'ng-select';
import { QRCodeModule } from 'angular2-qrcode';
import { Router } from '@angular/router';
import * as Sentry from '@sentry/angular';

import { AppRoutingModule } from './app-routing.module';
import { AppComponent } from './app.component';

import { LoginPageComponent } from './login-page/login-page.component';
import { LogoutComponent } from './logout/logout.component';
import { PageNotFoundComponent } from './page-not-found/page-not-found.component';
import { QueuePrinterVerticalComponent } from './queue-printer/queue-printer-vrt/queue-printer-vrt.component';
import { QueuePrinterHorizontalComponent } from './queue-printer/queue-printer-horz/queue-printer-horz.component';
import { QueuePrinterExtraQueuesComponent } from 'app/queue-printer/queue-printer-extra-queues/queue-printer-extra-queues.component';
import { QueuePrinterQrComponent } from 'app/queue-printer/queue-printer-qr/queue-printer-qr.component';
import { QueuePrinterQrFocusComponent } from 'app/queue-printer/queue-printer-qr-focus/queue-printer-qr-focus.component';
import { QueuePrinterVerticalNonMarketingComponent } from 'app/queue-printer/queue-printer-vrt-non-marketing/queue-printer-vrt-non-marketing.component';
import { QueuePrinterPrimaryQueueInFocusComponent } from 'app/queue-printer/queue-printer-primary-queue-in-focus/queue-printer-primary-queue-in-focus.component';
import { QueuePrinterComponent } from './queue-printer/queue-printer.component';
import { RoofCounterComponent } from './roof-counter/roof-counter.component';
import { AuthButtonComponent, AuthButtonService } from './auth-button/index';
import { PusherService } from './common/_services/pusher.service';

import {
  AuthEndpointsService,
  DataService,
  HeaderService,
  TickerDirective,
  LoaderComponent,
  SecondsToDateTime,
  SecondsToMinuteSeconds,
  TruncatePipe,
  BranchService,
  TranslatePipe,
} from './common/index';
import { MainPageComponent } from './main-page/main-page.component';
import { HeaderComponent } from './header/index';
import { ServiceWorkerModule } from '@angular/service-worker';
import { environment } from '../environments/environment';
import { ImageCarouselComponent } from './image-carousel/image-carousel.component';
import { TicketModalComponent } from './ticket-modal/ticket-modal.component';
import { CancelAutoRedirectButtonComponent } from './cancel-auto-redirect-button/cancel-auto-redirect-button.component';
import { MonitorScreenComponent } from './monitor-screen/monitor-screen.component';

@Injectable()
export class MyHammerConfig extends HammerGestureConfig {}

@NgModule({
  declarations: [
    AppComponent,
    LoginPageComponent,
    LogoutComponent,
    PageNotFoundComponent,
    SecondsToDateTime,
    QueuePrinterVerticalComponent,
    QueuePrinterHorizontalComponent,
    QueuePrinterExtraQueuesComponent,
    QueuePrinterQrComponent,
    QueuePrinterQrFocusComponent,
    QueuePrinterVerticalNonMarketingComponent,
    QueuePrinterPrimaryQueueInFocusComponent,
    QueuePrinterComponent,
    AuthButtonComponent,
    MainPageComponent,
    HeaderComponent,
    TickerDirective,
    LoaderComponent,
    SecondsToMinuteSeconds,
    TruncatePipe,
    TranslatePipe,
    RoofCounterComponent,
    ImageCarouselComponent,
    TicketModalComponent,
    CancelAutoRedirectButtonComponent,
    MonitorScreenComponent,
  ],
  imports: [
    BrowserModule,
    FormsModule,
    ReactiveFormsModule,
    HttpClientModule,
    AppRoutingModule,
    SelectModule,
    QRCodeModule,
    BrowserAnimationsModule,
    ServiceWorkerModule.register('ngsw-worker.js', {
      enabled: environment.production,
    }),
  ],
  providers: [
    AuthButtonService,
    AuthEndpointsService,
    DataService,
    BranchService,
    HeaderService,
    PusherService,
    {
      provide: HAMMER_GESTURE_CONFIG,
      useClass: MyHammerConfig,
    },
    {
      provide: ErrorHandler,
      useValue: Sentry.createErrorHandler({}),
    },
    {
      provide: Sentry.TraceService,
      deps: [Router],
    },
    {
      provide: APP_INITIALIZER,
      useFactory: () => () => {},
      deps: [Sentry.TraceService],
      multi: true,
    },
  ],

  bootstrap: [AppComponent],
})
export class AppModule {}
