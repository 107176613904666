import { Injectable } from '@angular/core';

import { Locale } from './branch.service';
import { PRINT_TEXTS } from './printTranslations';

declare var StarWebPrintBuilder: any;
declare var StarWebPrintTrader: any;

export const NO_PRINTER_CONNECTION_ERROR = 'NO_PRINTER_CONNECTION_ERROR';
export const GENERAL_PRINTER_ERROR = 'GENERAL_PRINTER_ERROR';
export const BUSY_PRINTER_ERROR = 'BUSY_PRINTER_ERROR';
export const CONNECTION_TIMED_OUT_ERROR = 'CONNECTION_TIMED_OUT_ERROR';

@Injectable()
export class PrintService {
  private trader;
  private ticket;
  private printerQueue;
  private printerStatus = false;
  private yAxis;
  private textSize;
  private weekDayHash = new Object({
    monday: 'Ma',
    tuesday: 'Ti',
    wednesday: 'Ons',
    thursday: 'To',
    friday: 'Fre',
    saturday: 'Lør',
    sunday: 'Søn',
  });
  private imageWidth;
  private imageHeight;

  checkPrinterStatus() {
    let printerPromise = new Promise((resolve, reject) => {
      this.trader.sendMessage({});
      this.setTraderPromiseHandlers(this.trader, resolve, reject);
    });

    return printerPromise;
  }

  setTraderPromiseHandlers(trader, resolve, reject) {
    trader.onReceive = function (response) {
      this.printerDisconnected = response.traderSuccess !== 'true';

      if (!this.printerDisconnected) resolve(response);
      else {
        response.traderCode && response.traderCode === '2001'
          ? reject(BUSY_PRINTER_ERROR)
          : reject(GENERAL_PRINTER_ERROR);
      }
    };

    trader.onError = function (response) {
      this.printerDisconnected = true;
      console.error(response);
      reject(NO_PRINTER_CONNECTION_ERROR);
    };
  }

  initCanvas(canvas) {
    canvas.width = 600;
    let additionalHeight = 0;
    // TODO: We need to improve this a bit further. Right now, pure rectangle
    //       images do not have a lot of hieght based on radio 220x150.
    //       We need to adjust the ticket height according to the height of
    //       the ratio if it doesnt not take full 150 height.
    if (
      this.ticket.attachment ||
      (this.ticket.ticketText && this.ticket.ticketText.length > 0)
    ) {
      additionalHeight += 100;
      if (this.ticket.attachment) {
        additionalHeight += 130;
      }
      if (this.ticket.ticketText) {
        additionalHeight += this.ticket.ticketText.length * 30;
      }
    }

    additionalHeight += 30;
    canvas.height = additionalHeight;
  }

  fillImage(canvas, context, img) {
    if (!this.ticket.attachment) return;

    // Note: This is a hard coding but also makes sure that image is
    //       centre aligned horizontally
    let wdDiff = (this.imageWidth * 110) / 220;
    // Note: Current expectation is that backend image recieved is of
    //       resolution(scaled) 220x150
    context.drawImage(img, canvas.width / 2 - wdDiff, this.yAxis);

    // Note: If our backend image height is scaled to be less
    //       then we want to find a scaled one and set that remaining height
    if (this.imageHeight == 150) {
      this.appendYAxis();
      this.appendYAxis();
      this.appendYAxis();
      this.appendYAxis();
      this.appendSmallYAxis();
      this.appendSmallYAxis();
      this.appendSmallYAxis();
      this.appendSmallYAxis();
    } else {
      let htDiff = (this.imageHeight * 184) / 150;
      this.appendSmallYAxis();
      this.appendSmallYAxis();
      this.appendYAxisCustom(htDiff);
    }
  }

  setCanvasText(img) {
    let canvas = document.createElement('canvas');
    this.initCanvas(canvas);

    let context = canvas.getContext('2d');
    context.textBaseline = 'middle';
    context.textAlign = 'center';
    let topMargin = 30;
    this.textSize = 30;
    this.yAxis = topMargin;

    //***********************STARTING ADVERTISEMENT CONTENT***********************
    // this.fillAdvertisementHeading(canvas, context);
    this.fillImage(canvas, context, img);

    // document.body.appendChild(canvas); // Print ticket at the end of web page

    return canvas;
  }

  appendSmallYAxis() {
    this.yAxis = this.yAxis + 8;
  }

  appendYAxis() {
    this.yAxis = this.yAxis + this.textSize + 8;
  }

  appendAdvertYAxis() {
    this.yAxis = this.yAxis + 40;
  }

  appendYAxisCustom(ht) {
    this.yAxis = this.yAxis + ht;
  }

  printerSlip(locale: Locale) {
    if (!this.ticket && !this.ticket.tokenNumber) return;

    try {
      const { queueName, tokenNumber, companyName, ticketText } = this.ticket;
      this.printerStatus = true;
      let vek = this;

      let img = new Image();

      img.onload = function () {
        // @ts-ignore
        vek.imageWidth = this.width;

        // @ts-ignore
        vek.imageHeight = this.height;
        let canvas = vek.setCanvasText(img);
        let context = canvas.getContext('2d');
        let builder = new StarWebPrintBuilder();
        let request = '';

        vek.trader.onReceive = function (response) {
          if (response.traderSuccess === 'true') {
            this.printerDisconnected = false;
          } else {
            this.printerDisconnected = true;
          }
        };

        vek.trader.onError = function (response) {
          this.printerDisconnected = true;
        };

        request += builder.createInitializationElement();
        request += builder.createAlignmentElement({ position: 'center' });

        request += builder.createTextElement({
          width: 2,
          height: 2,
          data: `${companyName}\n`,
          codepage: 'utf8',
        });

        request += builder.createTextElement({
          width: 6,
          height: 6,
          data: `${tokenNumber}\n`,
          emphasis: true,
          characterspace: 5,
        });

        const queueNameSize = { w: 4, h: 3 };

        if (queueName.length >= 10) {
          queueNameSize.w = 2;
          queueNameSize.w = 2;
        }
        if (queueName.length > 20) {
          queueNameSize.w = 1;
          queueNameSize.h = 1;
        }

        request += builder.createTextElement({
          width: queueNameSize.w,
          height: queueNameSize.h,
          data: `${queueName}\n`,
          emphasis: false,
          codepage: 'utf8',
        });

        request += builder.createTextElement({
          width: 1,
          height: 1,
          data: `${new Date(Date.now()).toLocaleString()}\n`,
          emphasis: false,
        });
        request += builder.createTextElement({ characterspace: 2 });

        if (ticketText) {
          request += builder.createTextElement({
            width: 1,
            height: 1,
            data: '\n_________________________________________\n',
          });

          request += builder.createAlignmentElement({ position: 'center' });
          request += builder.createTextElement({ data: '\n' });

          ticketText.split('\\n').forEach(
            (it) =>
              (request += builder.createTextElement({
                width: 1,
                height: 1,
                data: `${it}\n`,
                codepage: 'utf8',
              }))
          );
          request += builder.createTextElement({ data: '\n' });
        }

        if (vek.ticket.attachment) {
          request += builder.createBitImageElement({
            context: context,
            x: 0,
            y: 0,
            width: canvas.width,
            height: canvas.height,
          });
        }

        request += builder.createCutPaperElement({ feed: true });

        vek.trader.sendMessage({ request: request });
      };

      img.crossOrigin = 'Anonymous';
      if (this.ticket.attachment) {
        // Note: It should be in the following format:
        //       'data:image/jpeg;base64,' + this.ticket.attachment;
        img.src =
          'data:' +
          this.ticket.attachmentType +
          ';base64,' +
          this.ticket.attachment;
      } else {
        img.src = 'assets/images/slip_image.png';
      }
    } catch (e) {
      alert(e.message);
    }
  }

  loadPrinterQueue(printer) {
    this.printerQueue = printer;
  }

  loadPrintTicket(ticket) {
    this.ticket = ticket;
  }

  loadTrader() {
    let url =
      'http://' + this.printerQueue.ipAddress + '/StarWebPRNT/SendMessage';
    this.trader = new StarWebPrintTrader({ url: url });
  }

  getPrinterStatus() {
    return this.printerStatus;
  }

  getTrader() {
    return this.trader;
  }

  setPrinterStatus(val) {
    this.printerStatus = val;
  }

  getWeekDay(val: string): string {
    return this.weekDayHash[val.toLowerCase()];
  }
}
