import { Component, ViewChild, ElementRef, OnInit } from '@angular/core';
import { PrinterEndpointsService, PrintService } from '../../common/index';
import { QueuePrinterComponent } from '../queue-printer.component';
import { BranchQueue } from '../../common/_classes';

const SIZE_ONE_CHARACTER_LIMIT = 13;

@Component({
  selector: 'printer-horz',
  templateUrl: './queue-printer-horz.component.html',
  styleUrls: ['./queue-printer-horz.component.scss'],
  providers: [PrinterEndpointsService, PrintService],
})
export class QueuePrinterHorizontalComponent
  extends QueuePrinterComponent
  implements OnInit
{
  @ViewChild('bottomSection', { static: true }) bottomSection: ElementRef;
  viewType = 'horizontal';
  queueFontSizes: string[] = [];

  ngOnInit() {
    super.ngOnInit();

    super.loadPrinter(() => {
      const { qrCodeEnabled } = this.printerQueue;

      //decrease font size if name is too long;
      if (this.queues && this.queues.length > 0) {
        this.queueFontSizes = this.queues.map((it: BranchQueue) => {
          if (it.name.length > SIZE_ONE_CHARACTER_LIMIT) {
            return qrCodeEnabled ? '2.1vw' : '3.0vw';
          }

          return qrCodeEnabled ? '3.5vw' : '4.8vw';
        });
      }
    });
  }

  setQrSize() {
    if (!this.printerQueue) return;

    if (this.bottomSection) {
      this.printerQueue.qrSize =
        this.bottomSection.nativeElement.clientHeight - 16;
    } else this.printerQueue.qrSize = 300;
  }

  refreshPage() {
    location.reload();
  }
}
