import { Component, OnInit } from '@angular/core';
import { PrinterEndpointsService, PrintService } from '../../common/index';
import { QueuePrinterComponent } from '../queue-printer.component';

@Component({
  selector: 'printer-qr-focus',
  templateUrl: './queue-printer-qr-focus.component.html',
  styleUrls: ['./queue-printer-qr-focus.component.scss'],
  providers: [PrinterEndpointsService, PrintService],
})
export class QueuePrinterQrFocusComponent
  extends QueuePrinterComponent
  implements OnInit
{
  viewType = 'qr_in_focus';

  ngOnInit() {
    super.ngOnInit();
    super.loadPrinter();
  }

  setQrSize() {
    let width = 300;

    if (window.innerWidth > 1440) width = window.innerWidth / 6;
    else if (window.innerWidth < 980) width = window.innerWidth / 3;
    else width = window.innerWidth / 3.6;

    if (this.printerQueue) this.printerQueue.qrSize = width;
  }

  refreshPage() {
    location.reload();
  }
}
