import { platformBrowserDynamic } from '@angular/platform-browser-dynamic';
import { enableProdMode } from '@angular/core';
import { environment } from './environments/environment';
import { AppModule } from './app/app.module';
import * as Sentry from '@sentry/angular';
import { BrowserTracing } from '@sentry/tracing';
import { version } from '../package.json';

Sentry.init({
  dsn: environment.sentry.dsn,
  release: version,

  integrations: [
    new BrowserTracing({
      tracePropagationTargets: ['localhost', environment.apiUrl],
      routingInstrumentation: Sentry.routingInstrumentation,
    }),
  ],
  tracesSampleRate: 1.0,
  environment: process.env.NODE_ENV,
  enabled: process.env.NODE_ENV !== 'development',
});

if (!/localhost/.test(document.location.host)) {
  enableProdMode();
}

platformBrowserDynamic().bootstrapModule(AppModule);
