import { Component, OnInit, OnDestroy, HostListener, AfterViewChecked, NgZone } from '@angular/core';
import { Router, ActivatedRoute } from '@angular/router';
import Swal from 'sweetalert2';

import { BranchQueue } from '../common/_classes/branch-queue';
import {
  DataService,
  PrinterEndpointsService,
  Token,
  Printer,
  Ticket,
  PrintService,
  QrCode,
  BranchService,
  GlobalVariables,
} from '../common/index';
import { AuthButtonService } from '../auth-button/index';
import { NO_PRINTER_CONNECTION_ERROR, BUSY_PRINTER_ERROR } from '../common/_services/print.service';
import { NewTokenData, PusherService } from '../common/_services/pusher.service';

import {
  busyPrinterAlert,
  errorAlert,
  printerDisconnectedAlert,
  printerNoPaperAlert,
  isDesktop,
  printerConnectedAlert,
  makeConfirmationSwal,
} from './helper';

// TODO: We need to re-write this component in the next big refactor.
//       Change the views be based on *ngIf and use a single complenent.
//       Add divider ids so that styling can be differentiated in both views
//       Keep two stlying files instead of one big file with styling for both views
//       Add a loader in the start instead of showing an empty page
//       No router parameters. Get the printer then decide which view we want to load
@Component({
  selector: 'printer',
  templateUrl: './queue-printer.component.html',
  styleUrls: ['./queue-printer.component.scss'],
  providers: [PrinterEndpointsService, PrintService],
})
export class QueuePrinterComponent implements OnInit, OnDestroy, AfterViewChecked {
  mobileWebAppUrl = GlobalVariables.MOBILE_WEB_APP_URL + 'qr/';

  printerQueue: Printer;
  queues = [];
  queueLabel;
  routeId;
  viewType;
  token: Token;
  loggedIn;
  ticket: Ticket;
  allowUse = true;
  qrCode;
  allowChange = true;
  scrHeight: any;
  scrWidth: any;

  showModal = false;
  showToken = false;

  public showPrinterNotConnectedWarning = false;

  isDesktop = isDesktop;

  constructor(
    private router: Router,
    private route: ActivatedRoute,
    private dataService: DataService,
    private authButtonService: AuthButtonService,
    private printerEndpointsService: PrinterEndpointsService,
    private printService: PrintService,
    private zone: NgZone,
    public branchService: BranchService,
    private pusherService: PusherService
  ) {
    this.validateLogin();

    if (this.loggedIn) this.routeParams();

    this.printerQueue = new Printer();
    this.ticket = new Ticket();
    this.qrCode = new QrCode();

    this.getScreenSize();
    this.setQrSize();

    this.setCompanyLogo();
  }

  @HostListener('window:resize', ['$event'])
  getScreenSize(event?) {
    this.scrHeight = window.innerHeight;
    this.scrWidth = window.innerWidth;
  }

  @HostListener('window:popstate', ['$event'])
  onPopState(event) {
    this.showModal = false;
  }

  ngOnInit() {
    if (this.loggedIn) {
      const { branchId } = this.route.snapshot.params;
      this.branchService.getBranchDetails(branchId);

      this.zone.run(() => {
        console.log('enabled page re-render');
      });
    }
  }

  ngOnDestroy() {
    this.printerQueue = null;
    this.queues = null;
    this.routeId = null;
    this.viewType = null;
    this.token = null;
    this.loggedIn = null;
    this.ticket = null;
    this.allowUse = null;
    this.printService = null;
  }

  ngAfterViewChecked() {
    setTimeout(() => this.setQrSize(), 500);
  }

  initPusherSubscription() {
    this.pusherService.disconnectAllChannels();
    const branchId = this.route.snapshot.params['branchId'];
    this.pusherService.connectTokenBroadcast(branchId, this.handleNewTokenData);
  }

  handleNewTokenData = (data: NewTokenData) => {
    const { playSounds, branchQueue } = this.printerQueue;
    if (!data || !playSounds) return;

    const { branchQueueId, status, tokenNumber, totalPeopleInQueue } = data.data.attributes;

    const isRelevantToken = branchQueue.find((it) => it.id == branchQueueId);
    if (!isRelevantToken) return;

    if (status === 'pending' && totalPeopleInQueue === 1) {
      const { printerNewToken } = this.branchService.soundEffects;
      this.branchService.playTokenEventSound(printerNewToken);
    } else if (status === 'serving') {
      this.branchService.playTokenEventSound(null, tokenNumber);
    }
  };

  setQrSize() {
    if (this.printerQueue) this.printerQueue.qrSize = 80;
  }

  // Note: A common method that always refresh the company logo whenever page
  //       loads(with existing content) or is refreshed(to impose API change)
  setCompanyLogo() {
    if (this.printerQueue) {
      this.printerQueue.logo = this.dataService.CompanyLogo();
    }
  }

  validateLogin() {
    this.loggedIn = this.dataService.sessionData();
    if (!this.loggedIn) this.router.navigate(['/login']);
    this.authButtonService.login();
  }

  routeParams() {
    this.route.params.subscribe((params) => (this.routeId = +params['id']));
  }

  printerPage() {
    const branchId = this.route.snapshot.params['branchId'];
    const { viewType } = this.printerQueue;
    const url = `/branch/${branchId}/printer/${this.routeId}/${viewType}`;
    this.router.navigate([url]);
  }

  loadPrinter(callback?, startStautsCheck = true) {
    this.printerEndpointsService.getPrinterData(this.routeId).subscribe(
      (data: any) => {
        this.printerQueue.setPrinterData(data.data, data.included);

        if (this.printerQueue?.viewType !== this.viewType) {
          return this.printerPage();
        }

        this.dataService.setCompanyLogo(data.data.attributes.logo);
        this.setCompanyLogo();

        if (data.included) {
          const { included } = data;
          const dataResponseQueues = included.filter((x) => x.type === 'branchQueues');

          this.printerQueue.setQueuesData(dataResponseQueues);
          const { branchQueue, viewType, playSounds } = this.printerQueue;

          this.queues = branchQueue;

          this.queueLabel = viewType === 'extra_queues' ? 'Trekk kølapp her' : 'Klikk her for kølapp';

          if (this.queues.length === 1 && ['horizontal', 'vertical'].includes(viewType)) {
            this.queueLabel += '(Bruk en finger)';
          }
          if (playSounds) this.initPusherSubscription();
        }

        if (callback) callback();

        if (startStautsCheck && this.printerQueue.printerType !== 'ticket_less') {
          this.printService.loadPrinterQueue(this.printerQueue);
          this.printService.loadTrader();
          this.startBackgroundPrinterStatus();
        }
      },
      (error) => {
        errorAlert(error, () => {
          this.dataService.clearData();
          this.router.navigate(['login']);
        });
      }
    );
  }

  //overrided in child components to set font-sizes based on text length
  populateQueueFontSizes(queues: BranchQueue[]) {}

  startBackgroundPrinterStatus() {
    this.printService
      .checkPrinterStatus()
      .then((_) => {
        this.printService.setPrinterStatus(true);
        printerConnectedAlert();
      })
      .catch((error) => {
        console.error(error);

        this.printService?.setPrinterStatus(false);

        if (this?.printerQueue?.viewType === 'qr_in_focus') {
          this.showPrinterNotConnectedWarning = true;
          return;
        }

        if (error === NO_PRINTER_CONNECTION_ERROR) printerDisconnectedAlert();
        else if (error === BUSY_PRINTER_ERROR) busyPrinterAlert();
        else printerNoPaperAlert();
      });
  }

  setModalData(data, queue) {
    this.ticket.setTicketData(data, queue, this.branchService.details.ticketText);
  }

  handleQueueButtonClick(queue) {
    const { hasConfirmation, confirmationCustomization } = this.printerQueue;

    if (hasConfirmation && confirmationCustomization) {
      this.printWithConfirmation(queue);
    } else {
      this.printSlip(queue);
    }
  }

  printWithConfirmation(queue) {
    const { confirmationCustomization, printerType } = this.printerQueue;

    const { yesText, noText, yesClickedHtmlText, noClickedHtmlText, printOnYes } = confirmationCustomization;

    // private print function to handle printOnYes
    const print = (yesClicked: boolean) => {
      setTimeout(() => {
        const clickedText = yesClicked ? yesClickedHtmlText : noClickedHtmlText;

        const noConfirmationActions = !yesText && !noText;
        const shouldPrintOnYesClick = yesClicked && yesText;
        const doPrint = shouldPrintOnYesClick || noConfirmationActions;

        if (clickedText) {
          Swal.fire({
            html: `<h2><strong>${clickedText}</strong></h2>`,
            showConfirmButton: false,
            timer: doPrint ? 1500 : 5000,
          }).then(() => doPrint && this.printSlip(queue));
        } else doPrint && this.printSlip(queue);
      }, 300);
    };

    if (queue.confirmationRequired) {
      const swalConfig = makeConfirmationSwal(confirmationCustomization);

      if (printerType === 'ticket_less') {
        this.printSlip(queue);
        Swal.fire(swalConfig);
        return;
      }

      Swal.fire(swalConfig).then((result) => print(!!result.isConfirmed));
    } else {
      this.printSlip(queue);
    }
  }

  printQueueSlip(queues) {
    if (queues?.length === 1) this.handleQueueButtonClick(queues[0]);
  }

  printSlip(queue) {
    if (this.printerQueue.printerType === 'ticket_less') {
      return this.generateTicketLessToken(queue);
    }

    if (!this.allowUse) return;
    this.allowUse = false;

    if (!this.printService.getPrinterStatus() || !!this.printService.getTrader().printerDisconnected) {
      printerDisconnectedAlert();
      this.allowUse = true;
      return;
    }

    this.showModal = true;

    this.printerEndpointsService.generateQueueToken(this.printerQueue, queue).subscribe(
      (data: any) => {
        this.token = new Token();
        this.token.setTokenData(data.data);
        this.setModalData(data.data, queue);

        this.printService.loadPrintTicket(this.ticket);
        this.printService.printerSlip(this.branchService.details.locale);

        setTimeout(() => (this.showToken = true), 1000);

        setTimeout(() => {
          this.showModal = false;
          this.showToken = false;
        }, 5000);
        this.allowUse = true;
      },
      (error) => {
        this.showModal = false;
        this.showToken = false;

        errorAlert(error, () => {
          this.dataService.clearData();
          this.router.navigate(['login']);
        });
      }
    );
  }

  generateTicketLessToken(queue) {
    if (!this.allowUse) return;
    this.allowUse = false;

    this.printerEndpointsService.generateQueueToken(this.printerQueue, queue).subscribe(
      (_) => (this.allowUse = true),
      (error) => {
        errorAlert(error, () => {
          this.dataService.clearData();
          this.router.navigate(['login']);
        });
      }
    );
  }

  showHover($event, queue) {
    if (this.allowChange) {
      queue.hover = true;
      this.allowChange = false;
    }
  }

  hideHover($event, queue) {
    setTimeout(() => {
      queue.hover = false;
      this.allowChange = true;
    }, 2 * 1000);
  }
}
