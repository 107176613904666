<div
  id="printer-page-data"
  [ngStyle]="{ background: printerQueue.backgroundColor }"
  class="qr-in-focus-printer-wrapper"
  (click)="printQueueSlip(queues)"
>
  <div class="logo-section">
    <img *ngIf="printerQueue.showLogo" (click)="refreshPage()" src="{{ printerQueue?.logo }}" alt="logo" />
  </div>

  <div class="top-section">
    <h1 [ngStyle]="{ color: printerQueue.labelColor }">{{ 'getTicketHere' | translate : branchService.details.locale }}</h1>
    <div class="qr-section">
      <h2 [ngStyle]="{ color: printerQueue.labelColor }">
        {{ 'digitalTicketOnPhone' | translate : branchService.details.locale
        }}<i [ngStyle]="{ background: printerQueue.labelColor }"></i>
      </h2>

      <p [ngStyle]="{ color: printerQueue.labelColor }">
        {{ 'scanQrForDigitalTicket' | translate : branchService.details.locale }}
      </p>

      <div *ngIf="printerQueue.qrCodeEnabled" class="qr-container">
        <qr-code
          *ngIf="printerQueue.qrCodeText"
          class="qr-image"
          [value]="[mobileWebAppUrl, printerQueue.qrCodeText].join('')"
          [size]="printerQueue.qrSize"
          [level]="'H'"
          [background]="'white'"
        ></qr-code>
      </div>
    </div>
  </div>

  <div class="container-fluid hrz-printer-control-wrapper">
    <div *ngIf="showPrinterNotConnectedWarning; else printer_connected" class="printer-not-connected-warning">
      {{ 'cannotPrintPaperTicket' | translate : branchService.details.locale }}
    </div>
    <ng-template #printer_connected>
      <div class="hrz-printer-bottom-section">
        <h2 class="queue-label" [ngStyle]="{ color: printerQueue.labelColor }">
          {{ 'forPaperTicket' | translate : branchService.details.locale }}
        </h2>
        <div class="queue-buttons-container">
          <button
            *ngFor="let queue of queues; let queueIndex = index"
            (click)="isDesktop() && handleQueueButtonClick(queue)"
            (touchstart)="!isDesktop() && handleQueueButtonClick(queue)"
            class="blue-button"
            [ngStyle]="{
              background: printerQueue.buttonBackgroundColor,
              color: printerQueue.buttonTextColor
            }"
          >
            {{ queue.name }}
          </button>
        </div>
      </div>
    </ng-template>
  </div>
</div>

<app-ticket-modal
  *ngIf="showModal"
  [logo]="branchService?.details?.logoOriginal"
  [customization]="{
    tokenFontFamily: printerQueue.tokenFontFamily,
    tokenBackgroundColor: printerQueue.tokenBackgroundColor,
    tokenFontColor: printerQueue.tokenFontColor
  }"
  [branchName]="branchService?.details?.name"
  [loading]="!showToken"
  [tokenNumber]="ticket?.tokenNumber"
>
</app-ticket-modal>
