import { Injectable } from '@angular/core';
import { HttpClient, HttpHeaders } from '@angular/common/http';
import { map, catchError } from 'rxjs/operators';
import { Observable } from 'rxjs';
import { ActivatedRoute } from '@angular/router';

import { GlobalVariables } from '../global';
import { DataService } from './data.service';

@Injectable()
export class EmployeeConsoleEndpointsService {
  private basicUrl = GlobalVariables.BASE_API_URL + '/api/v1/branches';
  constructor(private http: HttpClient, private dataService: DataService, private route: ActivatedRoute) {}

  getCounterRoofData(counterId, branchQueueId) {
    const headers = this.defaultHeaders();
    const options = { headers };
    let url =
      this.basicUrl +
      '/' +
      this.route.snapshot.params['branchId'] +
      '/branch_queues/' +
      parseInt(branchQueueId) +
      '/counters/' +
      counterId +
      '?include=serving_token_for_current_day,branch_queue';

    return this.http.get(url, options);
  }

  // See src/app/monitor-screen/monitor-screen.component.ts for comment on this method.
  getMontiorScreenData() {
    const headers = this.defaultHeaders();
    const { branchId, id } = this.route.snapshot.params;
    const options = { headers };

    const url = `${this.basicUrl}/${branchId}/monitor_screens/${id}?include=counters,branch_queues`;
    return this.http.get(url, options);
  }

  getCounterDisplayCustomization(counterId?: number | string) {
    const headers = this.defaultHeaders();
    const { branchId, id } = this.route.snapshot.params;
    const options = { headers };

    let url = `${this.basicUrl}/${branchId}/counter_customization`;
    if (counterId) url += `?counter_id=${counterId}`;

    return this.http.get(url, options);
  }

  getAllBranchMonitorScreens() {
    const headers = this.defaultHeaders();
    const options = { headers };
    const url = `${this.basicUrl}/${this.dataService.branchData()}/monitor_screens`;

    return this.http.get(url, options);
  }

  defaultHeaders() {
    let headers = new HttpHeaders({ 'Content-Type': 'application/json' });
    headers = headers.append('ACCESS-TOKEN', this.dataService.sessionData());
    return headers;
  }
}
