<div class="counter-luke" [ngStyle]="{ background: counter.backgroundColor }" (click)="refreshPage()">
  <div class="counter-wrapper" *ngIf="hasAvailableToken(); else noToken" [ngStyle]="{ background: counter.backgroundColor }">
    <div class="counter-data" [ngStyle]="{ background: counter.fontColor, 'font-family': counter.counterFontFamily }">
      <span class="before" [ngStyle]="{ background: counter.fontColor }"></span>
      <div
        class="counterContainer"
        [ngStyle]="{ color: counter.backgroundColor, 'font-size': counter.counterFontSize + 'px' }"
      >
        <div *ngIf="counter.customName">{{ counter.customName }}</div>
        <div [hidden]="counter.customName">
          <div *ngIf="counter.currentQueue && counter.currentQueue.name">
            {{ counterName(counter.name) }}
            {{ counterNumber(counter.name) }}
          </div>
        </div>
      </div>
      <span class="after" [ngStyle]="{ background: counter.fontColor }"></span>
    </div>
    <div
      [@tokenUpdate]="animateFlag"
      (@tokenUpdate.done)="animationDone($event)"
      class="queue-data"
      [ngStyle]="{
        color: counter.fontColor,
        'font-family': counter.tokenFontFamily,
        'font-size': counter.tokenFontSize + 'px'
      }"
      [style.fontFamily]="counter.tokenFontFamily"
    >
      {{ counter.currentlyServing.tokenNumber }}
    </div>
  </div>

  <ng-template #noToken>
    <div class="aligner" [ngStyle]="{ background: counter.backgroundColor }">
      <div class="aligner-item Aligner-item--top"></div>
      <div class="aligner-item">
        <div class="no-counter-img">
          <img src="{{ logoPath }}" alt="" />
        </div>
      </div>
      <div class="aligner-item Aligner-item--bottom"></div>
    </div>
  </ng-template>
</div>
