<div id="printer-page-data" class="montain-bg-wrapper" (click)="printQueueSlip(queues)">
  <div [ngStyle]="{ background: printerQueue.backgroundColor }">
    <div class="printer-ext-section">
      <div class="queue-container">
        <h1 class="queue-label" (click)="refreshPage()" [ngStyle]="{ color: printerQueue.labelColor }">
          {{ printerQueue?.displayTitle || queueLabel }}
        </h1>
        <div class="queue-container-block" [ngClass]="queues && queues.length > 4 ? 'queue-5-7' : 'queue-1-4'">
          <div class="blue-container" *ngFor="let queue of queues">
            <div
              (click)="isDesktop() && handleQueueButtonClick(queue)"
              (touchstart)="!isDesktop() && handleQueueButtonClick(queue)"
              class="blue-button printer-btn-{{ queue.id }}"
              [ngClass]="{ 'left-thumb-full': !queue.qrCodeEnabled }"
              [ngStyle]="{ background: printerQueue.buttonBackgroundColor, color: printerQueue.buttonTextColor }"
            >
              {{ queue.name | truncate : [queues.length <= 4 ? 50 : 22, '...'] }}
              <i class="fa fa-caret-right pull-right"></i>
            </div>
          </div>
        </div>
      </div>
      <div
        *ngIf="printerQueue.qrCodeEnabled"
        class="qr-click-release"
        [ngClass]="{ 'qr-click': qrCode.qrClicked, 'qr-click-release': !qrCode.qrClicked }"
      >
        <div class="qr-wrapper" [ngStyle]="{ background: getBackground(printerQueue) }">
          <qr-code
            class="qr-image"
            *ngIf="printerQueue.qrCodeText"
            [value]="[mobileWebAppUrl, printerQueue.qrCodeText].join('')"
            [size]="printerQueue.qrSize"
            [level]="'H'"
            [foreground]="printerQueue.qrCodeColor"
          >
          </qr-code>
          <span class="qrText">
            <div [ngStyle]="{ color: printerQueue.qrTextColor }">Skann QR-code</div>
            <div [ngStyle]="{ color: printerQueue.qrTextColor }" class="text-bold">trekk kølapp fra mobilen</div>
          </span>
        </div>
      </div>
    </div>
    <div class="clearfix"></div>
  </div>

  <div class="container-fluid printer-banner">
    <app-image-carousel [images]="printerQueue.attachments"></app-image-carousel>
  </div>
</div>

<app-ticket-modal
  *ngIf="showModal"
  [logo]="branchService?.details?.logoOriginal"
  [customization]="{
    tokenFontFamily: printerQueue.tokenFontFamily,
    tokenBackgroundColor: printerQueue.tokenBackgroundColor,
    tokenFontColor: printerQueue.tokenFontColor
  }"
  [branchName]="branchService?.details?.name"
  [loading]="!showToken"
  [tokenNumber]="ticket?.tokenNumber"
>
</app-ticket-modal>
