import { Token } from './token';
import { Counter } from './counter';
import { Mood } from './mood';
import { PrinterQueue } from './printer';

export class BranchQueue {
  public id;
  public name: string;
  public branchQueueName: string;
  public currentToken: Token;
  public counters: Array<Counter>;
  public value;
  public label;
  public animationFlag;
  public hover;
  public totalPeopleInQueue;
  public userAbandons;
  public mood: Mood;
  public sound;
  public confirmationRequired: boolean;
  public displayName: string;
  public backgroundColor: string;
  public subtext: string;
  public iconUrl: string;

  constructor() {
    this.animationFlag = false;
    this.userAbandons = 0;
    this.mood = new Mood();
  }

  setBranchQueueData(data) {
    this.id = data.id;
    this.name = data.attributes.name; // name can change if displayName is applied
    this.branchQueueName = data.attributes.name;
    this.totalPeopleInQueue = data.attributes.peopleInQueue;
    this.userAbandons = data.attributes.userAbandons;
    this.value = this.id;
    this.label = this.name;
    this.currentToken = new Token();
    this.sound = data.attributes.sound;
    this.currentToken.setTokenData(data.attributes.currentTokenNumber);
    this.counters = new Array<Counter>();
    this.confirmationRequired = !!data.confirmationRequired;
    this.iconUrl = data.attributes.iconUrl;

    return [this.id, this.name, this.currentToken];
  }

  applyPrinterQueuesData(printerQueue: PrinterQueue) {
    if (printerQueue?.branchQueueId != this.id) return;

    const { confirmationRequired, displayName, backgroundColor, subtext } = printerQueue;
    if (backgroundColor) this.backgroundColor = backgroundColor;
    this.confirmationRequired = !!confirmationRequired;
    if (displayName) this.name = displayName;
    if (subtext) this.subtext = subtext || '';
  }
}
