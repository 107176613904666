export function toggleAutoRedirectMode() {
  const autoRedirectMode = getAutoRedirectMode();
  const newValue = autoRedirectMode ? 'false' : 'true';
  window.localStorage.setItem('autoRedirectMode', newValue);
  return newValue;
}

export function getAutoRedirectMode(): boolean {
  return window.localStorage.getItem('autoRedirectMode') === 'true';
}

export function getAutoRedirectPath() {
  return window.localStorage.getItem('autoRedirectPath');
}

export function setAutoRedirectPath(url) {
  window.localStorage.setItem('autoRedirectPath', url);
}
