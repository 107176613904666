import { Component, OnInit, OnDestroy, NgZone } from '@angular/core';
import { Router } from '@angular/router';
import { FormBuilder, FormGroup, Validators } from '@angular/forms';
import Swal from 'sweetalert2';

import {
  DataService,
  GlobalVariables,
  Printer,
  BranchQueue,
  MainPageEndpointsService,
  PrinterEndpointsService,
  Counter,
} from '../common/index';
import { AuthButtonService } from '../auth-button/index';

import { getAutoRedirectMode, setAutoRedirectPath, toggleAutoRedirectMode } from '../common/autoRedirect.util';

declare var $: any;

@Component({
  selector: 'main',
  templateUrl: './main-page.component.html',
  styleUrls: ['./main-page.component.scss'],
  providers: [MainPageEndpointsService, PrinterEndpointsService],
})
export class MainPageComponent implements OnInit, OnDestroy {
  monitors;
  printers;
  queues;
  loggedIn;
  message;
  isConsoleCounter = false;
  isRoofCounter = false;
  queue;
  autoRedirectMode = false;

  ipAddress: FormGroup;
  submitted: boolean = false;
  showPrinterIp: boolean = false;
  pattern =
    '^(25[0-5]|2[0-4][0-9]|[01]?[0-9][0-9]?).(25[0-5]|2[0-4][0-9]|[01]?[0-9][0-9]?).(25[0-5]|2[0-4][0-9]|[01]?[0-9][0-9]?).(25[0-5]|2[0-4][0-9]|[01]?[0-9][0-9]?)$';

  constructor(
    private dataService: DataService,
    private router: Router,
    private authButtonService: AuthButtonService,
    private mainPageEndpoints: MainPageEndpointsService,
    private formBuilder: FormBuilder,
    private printerEndpointsService: PrinterEndpointsService,
    private zone: NgZone
  ) {
    this.validateLogin();
  }

  ngOnInit() {
    if (this.loggedIn) {
      this.loadQueuePrinters();
      this.loadQueues();
      this.loadMonitors();

      this.zone.run(() => {
        console.log('enabled page re-render');
      });
    }

    this.autoRedirectMode = getAutoRedirectMode();
  }

  ngOnDestroy() {
    this.monitors = null;
    this.printers = null;
    this.queues = null;
    this.loggedIn = null;
    this.message = null;
    this.isConsoleCounter = null;
    this.isRoofCounter = null;
    this.queue = null;
    this.ipAddress = null;
    this.pattern = null;
  }

  validateLogin() {
    this.loggedIn = this.dataService.sessionData();

    if (!this.loggedIn) {
      this.router.navigate(['/login']);
    }

    this.authButtonService.login();
  }

  loadMonitors() {
    this.mainPageEndpoints.getMonitorsData().subscribe(
      (res: any) => {
        const { data } = res;
        this.monitors = data.map((it) => ({ value: it.id, label: it.attributes.name }));
      },
      (error) => this.alertMessage(this.message, error)
    );
  }

  loadQueuePrinters() {
    this.mainPageEndpoints.getPrintersData().subscribe(
      (data: any) => {
        this.printers = new Array<Printer>();

        let printersData = data.data;

        for (var i = 0; i < printersData.length; i++) {
          let printerInstance = new Printer();
          printerInstance.setPrinterData(printersData[i]);
          this.printers.push(printerInstance);
        }
      },
      (error) => {
        if (error?.status == '401') {
          this.dataService.clearData();
          this.router.navigate(['login']);
          return;
        } else if (error?.status == '404') {
          this.message = GlobalVariables.ERROR_MESSAGE_404;
        } else {
          this.message = GlobalVariables.DEFAULT_ERROR_MESSAGE;
        }

        this.alertMessage(this.message, error);
      }
    );
  }

  loadQueues() {
    this.mainPageEndpoints.getQueuesData().subscribe(
      (data: any) => {
        this.queues = new Array<BranchQueue>();

        let queueData = data.data;
        for (var i = 0; i < queueData.length; i++) {
          let queueInstance = new BranchQueue();
          queueInstance.setBranchQueueData(queueData[i]);
          this.queues.push(queueInstance);
        }
      },
      (error) => {
        if (error[0] && error[0].status == '404') {
          this.message = GlobalVariables.ERROR_MESSAGE_404;
        } else {
          this.message = GlobalVariables.DEFAULT_ERROR_MESSAGE;
        }

        this.alertMessage(this.message, error);
      }
    );
  }

  loadCounters(queueData) {
    this.mainPageEndpoints.getCountersData(queueData).subscribe(
      (data: any) => {
        if (parseInt(data.data.id) == queueData.id && data.included) {
          queueData.counters = new Array<Counter>();
          let counterData = data.included;
          for (var i = 0; i < counterData.length; i++) {
            let counterInstance = new Counter();
            counterInstance.setCounterData(counterData[i]);
            queueData.counters.push(counterInstance);
          }
          this.queue = queueData;
        } else {
          this.queue = new BranchQueue();
        }
      },
      (error) => {
        if (error[0].status == '404') {
          this.message = GlobalVariables.ERROR_MESSAGE_404;
        } else {
          this.message = GlobalVariables.DEFAULT_ERROR_MESSAGE;
        }

        this.alertMessage(this.message, error);
      }
    );
  }

  onPrinterSelect(data) {
    this.showPrinterIp = true;
    this.isRoofCounter = false;
    this.isConsoleCounter = false;

    this.ipAddress = this.formBuilder.group({
      ipValue: [data.ipAddress, Validators.required],
      printer: [data, Validators.required],
    });
  }

  onConsoleQueueSelect(data) {
    this.showPrinterIp = false;
    this.queue = null;
    this.isRoofCounter = true;
    this.isConsoleCounter = false;
    this.loadCounters(this.queues.filter((x) => x.id == data.value)[0]);
    this.animateScroller('#queue-display-id');
  }

  isString(val) {
    return typeof val === 'string';
  }

  alertMessage(message, error = null) {
    Swal.fire({
      text: this.message,
      icon: 'warning',
      allowOutsideClick: false,
      showCancelButton: false,
      allowEscapeKey: false,
      confirmButtonColor: '#3085d6',
      confirmButtonText: 'Refresh the page!',
    }).then(() => {
      if (error[0] && error[0].status == '401') {
        this.dataService.clearData();
        this.router.navigate(['login']);
      } else {
        location.reload();
      }
    });
  }

  onRoofQueueSelect(data) {
    this.showPrinterIp = false;
    this.queue = null;
    this.isRoofCounter = false;
    this.isConsoleCounter = true;
    this.loadCounters(this.queues.filter((x) => x.id == data.value)[0]);
    this.animateScroller('#console-display-id');
  }

  loadRoofCounter(queue, queueCounter) {
    const branchId = this.dataService.branchData();
    let url = `/branch/${branchId}/branch-queue/${queue.id}/employee-console/${queueCounter.value}`;
    if (this.autoRedirectMode) setAutoRedirectPath(url);
    this.router.navigate([url]);
  }

  onMonitorSelect(data) {
    const branchId = this.dataService.branchData();
    const url = `/branch/${branchId}/monitor-screen/${data.value}`;
    if (this.autoRedirectMode) setAutoRedirectPath(url);
    this.router.navigate([url]);
  }

  scrollQueueCounter() {
    this.animateScroller('#queue-counter-display-id');
  }

  scrollConsoleCounter() {
    this.animateScroller('#console-counter-id');
  }

  animateScroller(id) {
    $('html,body').animate(
      {
        scrollTop: $(id).offset().top,
      },
      'slow'
    );
  }

  printerPage(value) {
    const branchId = this.dataService.branchData();
    let url = `/branch/${branchId}/printer/${value.printer.value}/${value.printer.viewType}`;
    if (this.autoRedirectMode) setAutoRedirectPath(url);
    this.router.navigate([url]);
  }

  onPrinterSubmit({ value, valid }: { value: any; valid: boolean }) {
    if (value.ipValue !== value.printer.ipAddress) {
      this.printerEndpointsService.updatePrinterIpAddress(this.dataService.branchData(), value).subscribe(
        (data) => {
          this.submitted = true;
          this.printerPage(value);
        },
        (error) => {
          if (error[0].status == '404') {
            this.message = GlobalVariables.ERROR_MESSAGE_404;
          } else {
            this.message = GlobalVariables.DEFAULT_ERROR_MESSAGE;
          }

          this.alertMessage(this.message, error);
        }
      );
    } else {
      this.printerPage(value);
    }
  }

  handleUseAutoRedirectChange() {
    const newValue = toggleAutoRedirectMode();
    if (newValue === 'false') setAutoRedirectPath('');
    this.autoRedirectMode = newValue === 'true';
  }
}
