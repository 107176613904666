/**
 * ! This component has been added to support Boots current setup of Qtime. They have no MDM to remotely
 * switch old counter displays to QtimeApp.net (QtimeAngular repo). This app (qtime-app-printer-angular)
 * should only be used for printer.
 *
 * ! Important, only use for Boots! Business logic specific to Boots is in this component.
 */

import { Component, OnInit, OnDestroy, NgZone } from '@angular/core';
import Swal from 'sweetalert2';
import { keyframes, trigger, animate, transition, style } from '@angular/animations';
import { Router, ActivatedRoute } from '@angular/router';

import { GlobalVariables, DataService, Counter, EmployeeConsoleEndpointsService } from '../common/index';
import { AuthButtonService } from '../auth-button/index';
import { NewTokenData, PusherService } from 'app/common/_services/pusher.service';

@Component({
  selector: 'roof-counter',
  templateUrl: './roof-counter.component.html',
  styleUrls: ['./roof-counter.component.scss'],
  providers: [EmployeeConsoleEndpointsService],
  animations: [
    trigger('tokenUpdate', [
      transition(
        '* => active',
        animate(
          4000,
          keyframes([
            style({ opacity: '0.0' }),
            style({ opacity: '1.0' }),
            style({ opacity: '0.0' }),
            style({ opacity: '1.0' }),
            style({ opacity: '0.0' }),
            style({ opacity: '1.0' }),
          ])
        )
      ),
    ]),
  ],
})
export class RoofCounterComponent implements OnInit, OnDestroy {
  counter;
  counterId;
  branchQueueId;
  loggedIn;
  animateFlag;
  logoPath;
  unSubscribeCable;

  constructor(
    private router: Router,
    private route: ActivatedRoute,
    private dataService: DataService,
    private authButtonService: AuthButtonService,
    private employeeService: EmployeeConsoleEndpointsService,
    private zone: NgZone,
    private pusherService: PusherService
  ) {
    this.validateLogin();
    this.counter = new Counter();
  }

  refreshPage() {
    location.reload();
  }

  ngOnInit() {
    if (this.loggedIn) {
      this.routeParams();
      this.loadRoofCounter();
      this.initPusherSubscription();

      this.zone.run(() => {
        console.log('enabled page re-render');
      });
    }
  }

  ngOnDestroy() {
    this.counter = null;
    this.counterId = null;
    this.branchQueueId = null;
    this.loggedIn = null;
    this.animateFlag = null;
    this.pusherService.tokenBroadcastChannel.disconnect();
  }

  initPusherSubscription() {
    this.pusherService.disconnectAllChannels();
    const branchId = this.route.snapshot.params['branchId'];
    this.pusherService.connectTokenBroadcast(branchId, this.handleNewTokenData);
  }

  handleNewTokenData = (data: NewTokenData) => {
    if (!data || !data.data || !data.data.attributes) return;
    const { attributes, id } = data.data;
    const { counterId, status } = attributes;

    if (counterId == this.counter.id && status === 'serving') {
      this.counter.setCurrentlyServing({ id, ...attributes });
      this.animateFlag = 'active';
    }
  };

  validateLogin() {
    this.loggedIn = this.dataService.sessionData();

    if (!this.loggedIn) {
      this.router.navigate(['/login']);
    }

    this.authButtonService.login();
  }

  routeParams() {
    this.route.params.subscribe((params) => {
      this.counterId = +params['id'];
      this.branchQueueId = +params['branchQueueId'];
    });
  }

  loadRoofCounter() {
    this.employeeService.getCounterRoofData(this.counterId, this.branchQueueId).subscribe(
      (data: any) => {
        this.counter.setCounterData(data.data);
        if (data.data && data.data.attributes && data.data.attributes.logoOriginal) {
          this.logoPath = data.data.attributes.logoOriginal;
        }

        if (!data.included) data['included'] = [];

        data.included.forEach((it) => {
          if (it.type == 'branchQueues') {
            this.counter.setBranchQueue(it);
          }
        });

        this.navigateToMonitor();
      },
      (error) => {
        Swal.fire({
          text: this.errorMessage(error),
          icon: 'warning',
          allowOutsideClick: false,
          showCancelButton: false,
          allowEscapeKey: false,
          confirmButtonColor: '#3085d6',
          confirmButtonText: 'Refresh the page!',
        }).then(() => {
          if (error && error[0].status == '401') {
            this.dataService.clearData();
            this.router.navigate(['login']);
          } else {
            location.reload();
          }
        });
      }
    );
  }

  /**
   *  Since only Boots is using this deprecated view, we can hardcode the monitor screen navigation.
   *  No other clients whould be using this view, as QtimeAngular (qtimeapp.net) has the newer versions
   *  with more complete functionality.
   */
  navigateToMonitor() {
    this.employeeService.getAllBranchMonitorScreens().subscribe(
      (res: any) => {
        const { data } = res;
        const equivalentMonitorScreen = data.find((it) => it?.attributes?.name === this.counter.name);

        const branchId = this.dataService.branchData();
        if (!!equivalentMonitorScreen?.id && !!branchId) {
          this.router.navigate([`/branch/${branchId}/monitor-screen/${equivalentMonitorScreen.id}`]);
        }
      },
      (error) => {
        console.error(error);
      }
    );
  }

  animationDone($event) {
    this.animateFlag = 'inactive';
  }

  playTokenAnimation() {
    this.animateFlag = 'active';
  }

  hasAvailableToken() {
    return ['serving', 'served'].includes(this.counter?.currentlyServing?.status);
  }

  counterName(val) {
    return val.replace(/[0-9]/g, '');
  }

  counterNumber(val) {
    return val.replace(/\D|\s/g, '');
  }

  errorMessage(error) {
    let message;

    if (error[0] && error[0].status == '404') {
      message = GlobalVariables.ERROR_MESSAGE_404;
    } else if (error[0] && error[0].message) {
      message = error[0].message;
    } else {
      message = GlobalVariables.DEFAULT_ERROR_MESSAGE;
    }

    return message;
  }
}
